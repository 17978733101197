import { Routes, Route, Navigate } from 'react-router-dom';
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { Modules } from '@/features/modules/components/Modules';
import { ROUTES } from '@/shared/lib/const';
import Login from '@/features/auth/login';
import ClientList from '@/features/auth/selectClient';
import { Module } from '@/features/module/components/Module';
import StoryScenarios from '@/features/stories/scenarios';
import BannerScenarios from '@/features/banners/scenarios';
import BannerScenario from '@/features/banners/scenario';
import StoryScenario from '@/features/stories/scenario';
import { AuthorizedPagesLayoutBasic } from '@/layouts/AuthorizedPagesLayoutBasic/basic';
import Story from '@/features/stories/story';
import Slide from '@/features/stories/slide';
import Promotions from '@/features/promotions';
import { ClientSettings } from '@/features/sidebarMenus/MainMenu/ClientSettings';
import Banner from '@/features/banners/banner';
import NotificationTasks from '@/features/notificationTasks/notificationTasks';
import NotificationTask from '@/features/notificationTasks/notificationTask';
import ServicesScreenVersionList from '@/features/servicesScreen/versionList';
import ServiceScreenVersionContainer from '@/features/servicesScreen/version';
import { dispatchersWorkplaceRoutes } from '@/features/dispatchers-workplace/routes';
import { Page404NotFound } from '@/features/general/Page404NotFound';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AuthorizedPagesLayoutBasic Content={Modules} />} />
      <Route path={ROUTES.service} element={<AuthorizedPagesLayoutBasic Content={Modules} />} />
      <Route path={ROUTES.slide} element={<AuthorizedPagesLayoutBasic Content={Slide} />} />
      <Route path={ROUTES.story} element={<AuthorizedPagesLayoutBasic Content={Story} />} />
      <Route
        path={ROUTES.storyScenarios}
        element={<AuthorizedPagesLayoutBasic Content={StoryScenarios} />}
      />
      <Route
        path={ROUTES.storyScenario}
        element={<AuthorizedPagesLayoutBasic Content={StoryScenario} />}
      />
      <Route
        path={ROUTES.promotions}
        element={<AuthorizedPagesLayoutBasic Content={Promotions} />}
      />
      <Route path={ROUTES.login} element={<Login />} />
      <Route path={ROUTES.selectClient} element={<ClientList />} />
      <Route
        path={ROUTES.settings}
        element={<AuthorizedPagesLayoutBasic Content={ClientSettings} isShowMiddleMenu={false} />}
      />
      <Route
        path={ROUTES.banners}
        element={<AuthorizedPagesLayoutBasic Content={BannerScenarios} />}
      />
      <Route
        path={ROUTES.bannerScenario}
        element={<AuthorizedPagesLayoutBasic Content={BannerScenario} />}
      />
      <Route path={ROUTES.banner} element={<AuthorizedPagesLayoutBasic Content={Banner} />} />
      <Route
        path={ROUTES.notificationTasks}
        element={<AuthorizedPagesLayoutBasic Content={NotificationTasks} />}
      />
      <Route
        path={ROUTES.notificationTask}
        element={<AuthorizedPagesLayoutBasic Content={NotificationTask} />}
      />
      <Route
        path={ROUTES.servicesScreen.versionList}
        element={<AuthorizedPagesLayoutBasic Content={ServicesScreenVersionList} />}
      />
      <Route
        path={ROUTES.servicesScreen.version}
        element={
          <AuthorizedPagesLayoutBasic
            isShowMiddleMenu={false}
            Content={ServiceScreenVersionContainer}
          />
        }
      />
      {dispatchersWorkplaceRoutes()}
      <Route
        path="/404"
        element={<AuthorizedPagesLayoutBasic isShowMiddleMenu={false} Content={Page404NotFound} />}
      />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </>
  )
);
