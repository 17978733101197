import styled from 'styled-components';

import { MenuButtonWrapper as SharedMenuButtonWrapper } from '@/features/sidebarMenus/shared';

// export const BackButtonWrapper = styled.div`
//   display: flex;
//   position: relative;
//   align-items: center;
//   min-height: 42px;
//   padding: 0 10px;
//   user-select: none;
// `;

export const BackButtonWrapper = styled(SharedMenuButtonWrapper)`
  min-height: 42px;
  padding: 10px 10px 10px 15px;
`;
