import { FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MainWrapper } from './elements';
import { AxiosRequestInterceptorProvider } from '@/shared/providers/AxiosRequestInterceptorProvider';
import { AxiosErrorInterceptorProvider } from '@/shared/providers/AxiosErrorInterceptorProvider';

export const unauthorizedPagesLayout = (Content: FC) => {
  return (
    <AxiosRequestInterceptorProvider>
      <AxiosErrorInterceptorProvider>
        <Scrollbars style={{ width: '100vw', height: '100vh' }}>
          <MainWrapper>
            <Content />
          </MainWrapper>
        </Scrollbars>
      </AxiosErrorInterceptorProvider>
    </AxiosRequestInterceptorProvider>
  );
};
