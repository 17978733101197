import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

export const ServiceListContainerSortable = styled(ReactSortable)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 142px;
  width: 460px;
  min-width: 460px;
  min-height: 616px;
  margin-bottom: 20px;
`;
