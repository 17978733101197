import Button from '@mui/material/Button';
import { styled } from '@mui/system';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const SecondaryButton = styled(Button)(({ theme, size }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return `
    position: relative;
    height: ${!size && '40px'};
    padding: ${!size && '5px 20px'};
    cursor: pointer;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    font-weight: 600;
    color: ${styledTheme.colors.button.secondary.text};
    border-radius: 10px;
    border: 1px solid ${styledTheme.colors.button.secondary.border};
    outline: none;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  `;
});
