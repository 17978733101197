import { Logo, SmartPlatformLogoWrapper, Text } from './elements';

export const SmartPlatformLogo = ({ ...rest }) => {
  return (
    <SmartPlatformLogoWrapper {...rest}>
      <Logo />
      <Text>Smart Platform</Text>
    </SmartPlatformLogoWrapper>
  );
};
