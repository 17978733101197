import { FC, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SxProps } from '@mui/material';

import { AddStoryWrapper, StoryPlusIcon } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import { createAndSetNewStory } from '@/features/stories/redux/stories.slice';
import { ERequestStatus } from '@/shared/lib/types';
import { SecondaryButton } from '@/shared/components/SecondaryButton';

export interface IAddStoryProps {
  isRegularButton?: boolean;
  sx?: SxProps;
}

export const AddStory: FC<IAddStoryProps> = ({ isRegularButton = false, sx = {} }) => {
  const { scenario } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveStorySequenceStatus = useSelector(
    (state: TRootState) => state.stories.saveStorySequenceStatus
  );
  const newStories = useSelector((state: TRootState) => state.stories.newStories);

  const isBlockAddingStory = useMemo(() => {
    return saveStorySequenceStatus === ERequestStatus.pending;
  }, [saveStorySequenceStatus]);

  const isAddStoryDisabled = useMemo(() => {
    return !!newStories[scenario];
  }, [scenario, newStories]);

  const handleAddStoryClick = useCallback(() => {
    if (!isAddStoryDisabled) {
      dispatch(createAndSetNewStory(scenario));
      navigate('story/new_story');
    }
  }, [isAddStoryDisabled]);

  return (
    <>
      {isRegularButton ? (
        <SecondaryButton
          disabled={isAddStoryDisabled || isBlockAddingStory}
          onClick={handleAddStoryClick}
          sx={sx}
        >
          Создать новую
        </SecondaryButton>
      ) : (
        <AddStoryWrapper
          $isAddStoryDisabled={isAddStoryDisabled}
          $isBlockAddingStory={isBlockAddingStory}
          onClick={handleAddStoryClick}
          sx={sx}
        >
          <StoryPlusIcon>add</StoryPlusIcon>
        </AddStoryWrapper>
      )}
    </>
  );
};
