import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { DivKit } from '@divkitframework/react';
import isEmpty from 'lodash.isempty';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { TRootState, useAppDispatch } from '@/store';
import {
  VersionContainer,
  VersionHeading,
  VersionControlsContainer,
  FooterControlsContainer,
  MainContentSection,
  LeftSection,
  RightSection,
  ServicesScreenHeadingTextField,
  PublishControlsLeftContainer,
  PublishControlsRightContainer,
  BackBtnIcon,
} from './elements';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { Box, CircularProgress } from '@mui/material';
import { MainButton } from '@/shared/components/MainButton';
import { ERequestStatus } from '@/shared/lib/types';
import { SecondaryButton } from '@/shared/components/SecondaryButton';

import Yup from '@/shared/validations';
import {
  clearServicesScreenVisualizationData,
  setServicesScreenActivePage,
} from '@/features/servicesScreen/redux/servicesScreen.slice';
import { ServiceList } from './components/ServiceList';
import {
  EServicesScreenPage,
  EServicesScreenPlatform,
  IServiceListItem,
  IServicesScreenServiceData,
} from '@/features/servicesScreen/types';
import {
  fetchServicesScreenServiceBulkRequest,
  fetchServicesScreenVisualizationData,
  publishServicesScreenServiceList,
  saveServicesScreenVersion,
} from '@/features/servicesScreen/redux/servicesScreen.actions';
import {
  maxServicesAmount,
  publishingPlatformOptionTexts,
  publishingPlatformOptionValues,
} from '@/features/servicesScreen/const';
import { isServiceListTouched } from '@/features/servicesScreen/utils';
import { showErrorNotification, showSuccessNotification } from '@/shared/utils';
import { ROUTES } from '@/shared/lib/const';
import isEqual from 'lodash.isequal';
import CustomRadioGroup from '@/shared/components/CustomRadioGroup';
import { Link } from 'react-router-dom';
import { StyledLink } from './elements/StyledLink';
import { ConfirmRouteChangeModal } from './components/ConfirmRouteChangeModal';
import { useUnsavedChangesGuard } from '@/shared/hooks/useUnsavedChangesGuard';
import { StyledCircularProgress } from '@/shared/components/StyledCircularProgress';

const validationSchema = Yup.object().shape({
  name: Yup.string().itemNameInputValidation(),
});

export interface IServiceScreenVersionContentProps {
  servicesLocal: IServiceListItem[];
  setServicesLocal: (services: IServiceListItem[]) => void;
  servicesNameLocal: string;
  setServicesNameLocal: (serviceName: string) => void;
  previousServices: IServiceListItem[];
  setActiveServiceId: (serviceId: number) => void;
  bulkUpdateServices: (data: IServicesScreenServiceData[]) => void;
  setIsShowLoader: (isShowLoader: boolean) => void;
}

export const ServiceScreenVersionContent: FC<IServiceScreenVersionContentProps> = ({
  servicesLocal,
  setServicesLocal,
  servicesNameLocal,
  setServicesNameLocal,
  setActiveServiceId,
  bulkUpdateServices,
  setIsShowLoader,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { version } = useParams();
  const serviceListData = useSelector((state: TRootState) => state.servicesScreen.serviceListData);
  const serviceListDataInitial = useSelector(
    (state: TRootState) => state.servicesScreen.serviceListDataInitial
  );
  const [selectedPlatform, setSelectedPlatform] = useState<EServicesScreenPlatform>(
    EServicesScreenPlatform.both
  );
  const visualizationData = useSelector(
    (state: TRootState) => state.servicesScreen.servicesScreenVisualizationData
  );
  const fetchServicesScreenVisualizationDataStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenVisualizationDataStatus
  );
  const activePage = useSelector((state: TRootState) => state.servicesScreen.activePage);
  const [isAllowAnyPageTransition, setIsAllowAnyPageTransition] = useState<boolean>(false);
  const [serviceVersionForPlannedPageTransition, setServiceVersionForPlannedPageTransition] =
    useState<string>(null);

  const isVisualizationDataLoading =
    fetchServicesScreenVisualizationDataStatus === ERequestStatus.idle ||
    fetchServicesScreenVisualizationDataStatus === ERequestStatus.pending;

  const isPublishButtonDisabled = useMemo(() => {
    return (
      !selectedPlatform || servicesLocal?.length === 0 || servicesLocal?.length > maxServicesAmount
    );
  }, [selectedPlatform, servicesLocal]);

  const isSaveOrderButtonDisabled = useMemo(() => {
    return servicesLocal?.length === 0 || servicesLocal?.length > maxServicesAmount;
  }, [servicesLocal]);

  const isAddServiceBtnDisabled = useMemo(() => {
    return servicesLocal?.length >= maxServicesAmount;
  }, [servicesLocal]);

  const isBulkServicesUpdateButtonDisabled = useMemo(() => {
    return servicesLocal?.length === 0;
  }, [servicesLocal]);

  const isAskRouteChangeConfirm = useMemo(() => {
    const initialServicesIds = serviceListData?.services.map((service) => {
      return service.id;
    });
    const servicesIdsData = servicesLocal.map((service) => {
      return service.id;
    });
    return (
      !isEqual(initialServicesIds, servicesIdsData) || serviceListData?.name !== servicesNameLocal
    );
  }, [serviceListData?.services, servicesLocal, servicesNameLocal]);

  const { handleNavigationConfirm, handleNavigationCancel, isBlocked } = useUnsavedChangesGuard(
    isAskRouteChangeConfirm && !isAllowAnyPageTransition
  );

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: (values, actions) => {},
  });

  useEffect(() => {
    if (servicesNameLocal) {
      formik.setFieldValue('name', servicesNameLocal);
    }
  }, [servicesNameLocal]);

  useEffect(() => {
    let fetchServicesScreenVisualizationDataPromise: any;
    if (servicesLocal?.length !== 0) {
      fetchServicesScreenVisualizationDataPromise = dispatch(
        fetchServicesScreenVisualizationData(servicesLocal)
      );
    }

    return () => {
      fetchServicesScreenVisualizationDataPromise?.abort();
      dispatch(clearServicesScreenVisualizationData());
    };
  }, [servicesLocal]);

  useEffect(() => {
    const handleWindowUnload: EventListener = (e: Event) => {
      const initialServicesIds = serviceListData?.services.map((service) => {
        return service.id;
      });
      const servicesIdsData = servicesLocal.map((service) => {
        return service.id;
      });

      if (
        !isEqual(initialServicesIds, servicesIdsData) ||
        serviceListData?.name !== servicesNameLocal
      ) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [serviceListData?.services, servicesLocal, servicesNameLocal]);

  useEffect(() => {
    setIsAllowAnyPageTransition(false);
  }, [version]);

  useEffect(() => {
    if (!isBlocked && serviceVersionForPlannedPageTransition) {
      setServiceVersionForPlannedPageTransition(null);
      navigate(
        ROUTES.servicesScreen.version.replace(':version', serviceVersionForPlannedPageTransition)
      );
    }
  }, [serviceVersionForPlannedPageTransition, isBlocked]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setServicesNameLocal(event.target.value);
    formik.setFieldValue('name', event.target.value);
  };

  const handleServiceListUpdate = (serviceListItems: IServiceListItem[]) => {
    setServicesLocal(serviceListItems);
  };

  const handlePublishServicesScreen = async () => {
    formik.setTouched({
      name: true,
    });
    formik.validateForm().then(async (errors) => {
      if (isEmpty(errors)) {
        if (
          isServiceListTouched({
            serviceList: servicesLocal,
            serviceListInitial: serviceListDataInitial?.services,
          })
        ) {
          dispatch(saveServicesScreenVersion({ services: servicesLocal, name: formik.values.name }))
            .unwrap()
            .then(async (data) => {
              const lastVersion = data.version.toString();
              await dispatch(
                publishServicesScreenServiceList({
                  version: lastVersion,
                  platform: selectedPlatform,
                })
              ).unwrap();
              showSuccessNotification({
                message: 'Экран Сервисы успешно опубликован',
              });
              setIsAllowAnyPageTransition(true);
              setServiceVersionForPlannedPageTransition(data?.version?.toString());
            });
        } else {
          await dispatch(
            publishServicesScreenServiceList({ version, platform: selectedPlatform })
          ).unwrap();
          showSuccessNotification({
            message: 'Экран Сервисы успешно опубликован',
          });
        }
      }
    });
  };

  const handleSaveServicesScreenVersion = () => {
    formik.setTouched({
      name: true,
    });

    formik.validateForm().then(async (errors) => {
      if (isEmpty(errors)) {
        dispatch(saveServicesScreenVersion({ services: servicesLocal, name: formik.values.name }))
          .unwrap()
          .then(async (data) => {
            showSuccessNotification({
              message: 'Экран Сервисы успешно сохранен',
            });
            setIsAllowAnyPageTransition(true);
            setServiceVersionForPlannedPageTransition(data?.version?.toString());
          });
      }
    });
  };

  const handlePlatformChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedPlatform(value as EServicesScreenPlatform);
  };

  const handleAddIconBtnClick = () => {
    dispatch(setServicesScreenActivePage(EServicesScreenPage.addService));
  };

  const handleBulkServicesUpdateBtnClick = () => {
    if (servicesLocal?.length > 0) {
      setIsShowLoader(true);
      const promises: Promise<IServicesScreenServiceData>[] = [];
      servicesLocal.map((service) => {
        const promise: Promise<IServicesScreenServiceData> = dispatch(
          fetchServicesScreenServiceBulkRequest(service.id.toString())
        ).unwrap();
        promises.push(promise);
      });

      Promise.all(promises)
        .then((data) => {
          bulkUpdateServices(data);
        })
        .catch(() => {
          setIsShowLoader(false);
          showErrorNotification({
            message: 'Ошибка: не удалось обновить иконки сервисов',
          });
        });
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <VersionContainer>
          <VersionHeading>
            <StyledLink to={ROUTES.servicesScreen.versionList}>
              <BackBtnIcon>arrow_back</BackBtnIcon>
            </StyledLink>
            Экран сервисы
          </VersionHeading>
          <VersionControlsContainer>
            <MainContentSection>
              <LeftSection>
                <ServicesScreenHeadingTextField
                  label="Заголовок"
                  type="text"
                  size="small"
                  value={formik.values.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mb: '10px' }}
                  onChange={handleNameChange}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <ServiceList
                  serviceList={servicesLocal}
                  setActiveServiceId={setActiveServiceId}
                  onUpdate={handleServiceListUpdate}
                ></ServiceList>
                <FooterControlsContainer>
                  <PublishControlsLeftContainer>
                    <CustomRadioGroup
                      label="Платформа"
                      value={selectedPlatform}
                      radioGroupValues={publishingPlatformOptionValues}
                      radioGroupTexts={publishingPlatformOptionTexts}
                      onChange={handlePlatformChange}
                      sxWrapper={{ mb: '5px' }}
                    />
                    <MainButton
                      sx={{ mb: 2, width: '150px' }}
                      disabled={isPublishButtonDisabled}
                      onClick={handlePublishServicesScreen}
                    >
                      Опубликовать
                    </MainButton>
                  </PublishControlsLeftContainer>
                  <PublishControlsRightContainer>
                    <SecondaryButton
                      sx={{ mb: 2, width: '190px' }}
                      startIcon={<AddCircleOutlineRoundedIcon style={{ fontSize: 24 }} />}
                      disabled={isAddServiceBtnDisabled}
                      onClick={handleAddIconBtnClick}
                    >
                      Добавить иконку
                    </SecondaryButton>
                    <SecondaryButton
                      sx={{ mb: 2, width: '190px' }}
                      disabled={isSaveOrderButtonDisabled}
                      onClick={handleSaveServicesScreenVersion}
                    >
                      Сохранить порядок
                    </SecondaryButton>
                    <SecondaryButton
                      sx={{ width: '190px' }}
                      disabled={isBulkServicesUpdateButtonDisabled}
                      onClick={handleBulkServicesUpdateBtnClick}
                    >
                      Обновить иконки
                    </SecondaryButton>
                  </PublishControlsRightContainer>
                </FooterControlsContainer>
              </LeftSection>
              <RightSection>
                <TextFieldGroup sx={{ mt: '-9px' }} title="Изображение экрана Сервисы">
                  {visualizationData ? (
                    <Box
                      sx={{
                        width: '400px',
                        height: '100%',
                        background: '#ffffff',
                        border: '1px solid orange',
                      }}
                    >
                      <DivKit id="services-screen" json={visualizationData} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '400px',
                        height: '580px',
                      }}
                    >
                      {isVisualizationDataLoading ? <StyledCircularProgress size="34px" /> : null}
                    </Box>
                  )}
                </TextFieldGroup>
              </RightSection>
            </MainContentSection>
          </VersionControlsContainer>
        </VersionContainer>
        <ConfirmRouteChangeModal
          open={isBlocked && !isAllowAnyPageTransition}
          onCancel={handleNavigationCancel}
          onConfirm={handleNavigationConfirm}
        />
      </Form>
    </FormikProvider>
  );
};
