import { IServiceListItem } from '../types';

export const transformServicesDataBeforeVisualize = (services: IServiceListItem[]) => {
  const transformedServicesData = services.map((service) => {
    return {
      id: service.id,
      name: service.name,
      image_url: service.image_url,
    };
  });

  return {
    data: {
      services: transformedServicesData,
    },
  };
};
