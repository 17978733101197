import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { VersionListContainer, VersionListHeading, VersionListSubHeading } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { IModuleRecord } from '@/features/sidebarMenus/types';
import { VersionListTable } from './components/VersionListTable';
import { fetchServicesScreenVersionList } from '../redux/servicesScreen.actions';

const ServicesScreenVersionList = () => {
  const dispatch = useAppDispatch();
  const modules = useSelector((state: TRootState) => state.services.modules);
  const serviceListVersionsData = useSelector(
    (state: TRootState) => state.servicesScreen.serviceListVersionsData
  );

  useEffect(() => {
    dispatch(fetchServicesScreenVersionList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(modules));
  }, [dispatch, modules]);

  useEffect(() => {
    if (!!modules && modules.length > 0) {
      dispatch(setMiddleMenuActiveBtn((modules[4] as IModuleRecord).external_module_id));
    }
  }, [dispatch, modules]);

  return (
    <VersionListContainer>
      <VersionListHeading>Список версий</VersionListHeading>
      <VersionListSubHeading>История версий</VersionListSubHeading>
      <VersionListTable serviceListVersions={serviceListVersionsData?.versions} />
    </VersionListContainer>
  );
};

export default ServicesScreenVersionList;
