import { FC } from 'react';
import { SxProps } from '@mui/material';

import { EMuiThemeMode } from '@/app/types';
import { ThemeBtn } from './elements';

export interface ICollapsedThemeSwitchBtn {
  muiThemeMode?: EMuiThemeMode;
  onChange?: (muiThemeMode: EMuiThemeMode) => void;
  sx?: SxProps;
}

export const CollapsedThemeSwitchBtn: FC<ICollapsedThemeSwitchBtn> = ({
  muiThemeMode = EMuiThemeMode.light,
  onChange,
  sx,
}) => {
  return (
    <ThemeBtn
      onClick={() => {
        const nextThemeMode =
          muiThemeMode === EMuiThemeMode.dark ? EMuiThemeMode.light : EMuiThemeMode.dark;
        onChange(nextThemeMode);
      }}
      $theme={muiThemeMode}
      sx={sx}
    />
  );
};
