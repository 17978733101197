import { EMuiThemeMode } from '@/app/types';
import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div<{ $withGradient?: boolean }>`
  position: relative;
  height: 100vh;
  width: 195px;
  background-color: ${({ theme }) => theme.colors.middleMenuSidebar.background};
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.middleMenuSidebar.border};
    width: 1px;
    height: 100%;
    z-index: 4;
  }
`;
