import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TRootState, useAppDispatch } from '@/store';
import { Header } from './components/Header';
import SlideView from './components/SlideView';
import {
  SlideViewContainer,
  SlideContainer,
  SlideContent,
  SlideViewWrapper,
  ControlsContainer,
} from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { ControlsBar } from './components/ControlsBar';
import {
  addSlideToStory,
  createAndSetNewStory,
  setActiveSlideIdInSlidePage,
  setActiveStoryFromModified,
  setActiveStoryFromNew,
} from '../redux/stories.slice';
import { fetchStory } from '../redux/stories.actions';
import SlideViewDimensions from './components/SlideViewDimensions';

const Slide = () => {
  const { scenario, story: externalStoryId, slide } = useParams();
  const dispatch = useAppDispatch();
  const modifiedStories = useSelector((state: TRootState) => state.stories.modifiedStories);
  const newStories = useSelector((state: TRootState) => state.stories.newStories);
  const slides = useSelector((state: TRootState) => state.stories.story?.slides);

  useEffect(() => {
    if (slide !== undefined) {
      dispatch(setMiddleMenuActiveBtn(slide));
      dispatch(setActiveSlideIdInSlidePage(Number(slide)));
    }
  }, [slide]);

  useEffect(() => {
    if (slides) {
      dispatch(setMiddleMenuOptions(slides));
    }
  }, [slides]);

  useEffect(() => {
    if (!!scenario && !!externalStoryId) {
      if (
        !!modifiedStories[scenario] &&
        !!modifiedStories[scenario][externalStoryId] &&
        externalStoryId !== 'new_story'
      ) {
        dispatch(setActiveStoryFromModified({ scenario, externalStoryId }));
      } else if (newStories[scenario] && externalStoryId === 'new_story') {
        dispatch(setActiveStoryFromNew(scenario));
      } else if (externalStoryId === 'new_story') {
        dispatch(createAndSetNewStory(scenario));
        dispatch(addSlideToStory({ scenario, externalStoryId }));
      } else {
        dispatch(fetchStory({ story: externalStoryId }));
      }
    }
  }, [scenario, externalStoryId, modifiedStories, newStories, dispatch]);

  return (
    <SlideContainer>
      <SlideContent>
        <ControlsContainer>
          <SlideViewDimensions />
          <ControlsBar />
        </ControlsContainer>
        <SlideViewContainer>
          <SlideViewWrapper>
            <SlideView />
          </SlideViewWrapper>
        </SlideViewContainer>
      </SlideContent>
    </SlideContainer>
  );
};

export default Slide;
