import isEqual from 'lodash.isequal';

import { IServiceListItem } from '../types';

export interface IIsServiceListTouchedProps {
  serviceList: Array<IServiceListItem>;
  serviceListInitial: Array<IServiceListItem>;
}

export const isServiceListTouched = ({
  serviceList,
  serviceListInitial,
}: IIsServiceListTouchedProps) => {
  return !isEqual(serviceList, serviceListInitial);
};
