import { FC } from 'react';

import { Scenario, StyledLink } from '@/features/stories/scenarios/components/Card/elements';
import { ROUTES } from '@/shared/lib/const';

interface ICardProps {
  external_scenario_id: string;
  handleClassName?: string;
}

export const Card: FC<ICardProps> = (props) => {
  return (
    <StyledLink
      to={ROUTES.storyScenario.replace(':scenario', props.external_scenario_id)}
      className={props.handleClassName}
    >
      <Scenario>{props.external_scenario_id}</Scenario>
    </StyledLink>
  );
};
