import { useEffect, useMemo, useCallback, useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import isEqual from 'lodash.isequal';

import { CircularProgressWrapper, Footer, Header, StoriesContainer } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import { SortableList } from '@/shared/components/SortableList';
import { setStorySequence, setStorySequenceFromModified } from '../redux/stories.slice';
import { Card } from './components/Card';
import { IStorySequenceItem } from '../types';
import {
  setMiddleMenuOptions,
  setMiddleMenuActiveBtn,
} from '@/features/sidebarMenus/redux/services.slice';
import { useParams } from 'react-router-dom';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { AddStory } from './components/AddStory';
import {
  fetchScenarios,
  fetchStoryPreview,
  fetchStorySequence,
  saveStorySequence,
} from '../redux/stories.actions';
import { ERequestStatus } from '@/shared/lib/types';
import { MainButton } from '@/shared/components/MainButton';
import { isStorySequenceTouched } from '../utils';
import { showSuccessNotification } from '@/shared/utils';

const Scenario = () => {
  const { scenario: scenarioName } = useParams();
  const storySequence = useSelector((state: TRootState) => state.stories.storySequence);
  const storySequenceInitial = useSelector(
    (state: TRootState) => state.stories.storySequenceInitial
  );
  const storySequencesModified = useSelector(
    (state: TRootState) => state.stories.storySequencesModified
  );
  const saveStorySequenceStatus = useSelector(
    (state: TRootState) => state.stories.saveStorySequenceStatus
  );
  const fetchStorySequenceStatus = useSelector(
    (state: TRootState) => state.stories.fetchStorySequenceStatus
  );
  const scenarios = useSelector((state: TRootState) => state.stories.scenarios);
  const dispatch = useAppDispatch();
  const isSaveBtnDisabled = useMemo(() => {
    const newStory = storySequence.find((story) => {
      return story?.isNewStory;
    });
    if (newStory) {
      return (
        !newStory?.isSavedStory ||
        !isStorySequenceTouched({ storySequence, storySequenceInitial }) ||
        saveStorySequenceStatus === ERequestStatus.pending ||
        fetchStorySequenceStatus === ERequestStatus.pending
      );
    } else {
      return (
        !isStorySequenceTouched({ storySequence, storySequenceInitial }) ||
        saveStorySequenceStatus === ERequestStatus.pending ||
        fetchStorySequenceStatus === ERequestStatus.pending
      );
    }
  }, [storySequence, storySequenceInitial, saveStorySequenceStatus, fetchStorySequenceStatus]);

  const isShowLoader =
    fetchStorySequenceStatus === ERequestStatus.idle ||
    fetchStorySequenceStatus === ERequestStatus.pending;

  const updateStories = (nextStories: IStorySequenceItem[]) => {
    if (nextStories.length !== 0) {
      let isValidStorySequence = true;
      nextStories.forEach((story) => {
        if (!story) {
          isValidStorySequence = false;
        }
      });

      if (isValidStorySequence && !isEqual(storySequence, nextStories)) {
        dispatch(setStorySequence({ storySequence: nextStories, scenario: scenarioName }));
      }
    }
  };

  useEffect(() => {
    dispatch(fetchScenarios());
  }, []);

  useEffect(() => {
    if (!!storySequence && storySequence.length > 0) {
      storySequence.forEach((story) => {
        if (story?.external_story_id) {
          dispatch(fetchStoryPreview(story?.external_story_id));
        }
      });
    }
  }, [storySequence]);

  useEffect(() => {
    let fetchStorySequencePromise: any = null;

    if (scenarioName) {
      if (storySequencesModified[scenarioName]) {
        fetchStorySequencePromise = dispatch(fetchStorySequence(scenarioName))
          .unwrap()
          .then(() => {
            dispatch(setStorySequenceFromModified(scenarioName));
          });
      } else {
        fetchStorySequencePromise = dispatch(fetchStorySequence(scenarioName));
      }
    }

    return () => {
      fetchStorySequencePromise?.abort?.();
    };
  }, [scenarioName]);

  useEffect(() => {
    if (scenarios) {
      dispatch(setMiddleMenuOptions(scenarios));
    }
  }, [scenarios]);

  useEffect(() => {
    if (scenarioName) {
      dispatch(setMiddleMenuActiveBtn(scenarioName));
    }
  }, [scenarios, scenarioName]);

  const handleSaveBtnClick = useCallback(() => {
    if (!isSaveBtnDisabled) {
      dispatch(saveStorySequence(scenarioName))
        .unwrap()
        .then(() => {
          showSuccessNotification({
            message: 'Сценарий был успешно сохранен!',
          });
          dispatch(fetchStorySequence(scenarioName));
        });
    }
  }, [scenarioName, isSaveBtnDisabled]);

  return (
    <StoriesContainer>
      {fetchStorySequenceStatus === ERequestStatus.error ? null : isShowLoader ? (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      ) : (
        <>
          <Header>
            <MainButton
              onClick={handleSaveBtnClick}
              disabled={isSaveBtnDisabled}
              sx={{ marginRight: '20px' }}
            >
              Сохранить
            </MainButton>
            <AddStory isRegularButton sx={{ marginRight: '20px' }} />
            <SecondaryButton>История</SecondaryButton>
          </Header>
          <SortableList
            updateCards={updateStories}
            cards={storySequence}
            cardComponent={Card}
          ></SortableList>
          <Footer>
            <AddStory />
          </Footer>
        </>
      )}
    </StoriesContainer>
  );
};

export default Scenario;
