import {
  EServicesScreenPlatform,
  EServicesScreenPlatformName,
  EServiceListVersionStatus,
  EServiceListVersionStatusName,
  ICreateServiceServiceData,
  EServiceActionName,
} from '../types';

export const serviceListVersionStatusNames: Record<
  EServiceListVersionStatus,
  EServiceListVersionStatusName
> = {
  [EServiceListVersionStatus.draft]: EServiceListVersionStatusName.draft,
  [EServiceListVersionStatus.published_now]: EServiceListVersionStatusName.published_now,
  [EServiceListVersionStatus.published_previously]:
    EServiceListVersionStatusName.published_previously,
};

export const serviceListVersionPlatformNames: Record<
  EServicesScreenPlatform,
  EServicesScreenPlatformName
> = {
  [EServicesScreenPlatform.both]: EServicesScreenPlatformName.both,
  [EServicesScreenPlatform.android]: EServicesScreenPlatformName.android,
  [EServicesScreenPlatform.ios]: EServicesScreenPlatformName.ios,
};

export const publishingPlatformOptionValues = [
  EServicesScreenPlatform.both,
  EServicesScreenPlatform.android,
  EServicesScreenPlatform.ios,
];

export const publishingPlatformOptionTexts = [
  EServicesScreenPlatformName.both,
  EServicesScreenPlatformName.android,
  EServicesScreenPlatformName.ios,
];

export const maxServicesAmount = 15;

export const defaultCreateServiceServiceData: ICreateServiceServiceData = {
  name: '',
  image_url: '',
  onclick: {
    action_type: null,
    action_value: '',
  },
};

export enum EServiceActionType {
  deepLink = 'deep_link',
  browser = 'browser',
  webview = 'webview',
}

export const serviceActionTypeOptionValues = [
  EServiceActionType.browser,
  EServiceActionType.deepLink,
  EServiceActionType.webview,
];

export const serviceActionTypeOptionTexts = [
  EServiceActionName.browser,
  EServiceActionName.deepLink,
  EServiceActionName.webview,
];
