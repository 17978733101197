import { ElementType } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from 'styled-components';

export const DeleteSlideButton = styled(Button)<ButtonProps & { component: ElementType }>`
  padding: 5px !important;
  border: 1px solid ${({ theme }) => theme.colors.storyScreen.slide.border.main} !important;
  min-width: 37px !important;
  min-height: 37px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.storyScreen.slide.border.hover} !important;
  }
`;
