import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TRootState } from '@/store';
import { ClientListWrapper, Heading, MainContent } from './elements';
import { unauthorizedPagesLayout } from '@/layouts/unauthorizedPagesLayout';
import { ClientList } from './ClientList';
import { ROUTES } from '@/shared/lib/const';
import { IClientListRecord } from '@/shared/lib/types';
import { SmartPlatformLogo } from '../shared/SmartPlatformLogo';

const SelectClient = () => {
  const clients = useSelector((state: TRootState) => state.authentication.clients);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!clients && clients.length === 0) {
      navigate(ROUTES.login);
    }
  }, [clients]);

  const handleClientClick = (client: IClientListRecord) => {
    const dispatchersWorkplaceOnly = client.dwp_only;
    let url = client.client_url;

    if (dispatchersWorkplaceOnly) {
      url += 'service/internet-shop/module/dispatchers-workplace';
    }
    window.location.assign(url);
  };

  return (
    <MainContent>
      <SmartPlatformLogo />
      <Heading>Выберите клиента, пожалуйста.</Heading>
      <ClientListWrapper component="form" noValidate autoComplete="off">
        <ClientList clientListData={clients} onListItemClick={handleClientClick} />
      </ClientListWrapper>
    </MainContent>
  );
};

export default () => unauthorizedPagesLayout(SelectClient);
