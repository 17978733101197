import { ChangeEvent, FC, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import { MainButton } from '@/shared/components/MainButton';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { SearchInput } from '@/shared/components/SearchInput';
import { ModalContainer } from './elements';

const style = {
  boxShadow: 24,
};

interface IBasicModalProps {
  open: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const ConfirmRouteChangeModal: FC<IBasicModalProps> = ({
  open: openProp,
  onCancel,
  onConfirm,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (openProp) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openProp]);

  const handleConfirmBtnClick = () => {
    handleClose();
    onConfirm?.();
  };

  const handleCancelBtnClick = () => {
    handleClose();
    onCancel?.();
  };

  const handleBackdropClick = () => {
    handleClose();
    onCancel?.();
  };

  return (
    <Modal
      aria-labelledby="page-transition-modal-title"
      open={open}
      onClose={handleBackdropClick}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        '& .MuiBackdrop-root.MuiModal-backdrop': {
          backgroundColor: 'rgba(255, 255, 255, 0.65)',
        },
      }}
    >
      <Fade in={open}>
        <ModalContainer sx={style}>
          <Typography
            id="page-transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontSize: '18px' }}
          >
            Для экрана Сервисы были сделаны изменения, которые не были сохранены. При выходе со
            страницы они будут потеряны. Вы уверены, что хотите выйти?{' '}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <SecondaryButton sx={{ mr: 2 }} onClick={handleConfirmBtnClick}>
              Перейти
            </SecondaryButton>
            <MainButton onClick={handleCancelBtnClick}>Отмена</MainButton>
          </Box>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};
