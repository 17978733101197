import { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import {
  ServiceIcon,
  ServiceItemContainer,
  ServiceItemContent,
  ServiceItemFooter,
  ServiceItemName,
  ServiceItemNameContainer,
  ServiceListContainerSortable,
  SymbolDelete,
} from './elements';
import { TRootState } from '@/store';
import { EServicesScreenPage, IServiceListItem } from '@/features/servicesScreen/types';
import { ItemInterface } from 'react-sortablejs';
import { setServicesScreenActivePage } from '@/features/servicesScreen/redux/servicesScreen.slice';
import { useAppDispatch } from '@/store';

export interface IServiceListProps {
  serviceList: IServiceListItem[];
  onUpdate?: (serviceListItems: IServiceListItem[]) => void;
  setActiveServiceId: (serviceId: number) => void;
}

export const ServiceList: FC<IServiceListProps> = ({
  serviceList,
  onUpdate,
  setActiveServiceId,
}) => {
  const dispatch = useAppDispatch();
  const [serviceListLocal, setServiceListLocal] = useState<IServiceListItem[]>([]);

  useEffect(() => {
    setServiceListLocal(cloneDeep(serviceList));
  }, [serviceList]);

  const handleDeleteServiceItem = ({
    event,
    serviceId,
  }: {
    event: MouseEvent<HTMLElement>;
    serviceId: number;
  }) => {
    const filteredList = serviceListLocal.filter((item) => {
      return item.id !== serviceId;
    });

    setServiceListLocal(filteredList);
    onUpdate?.(filteredList);
    event.stopPropagation();
  };

  const handleSetList = (items: ItemInterface[]) => {
    if (items && items.length !== 0) {
      let orderChanged = false;
      serviceList.forEach((service, index) => {
        if (service?.id !== items[index]?.id) {
          orderChanged = true;
        }
      });
      if (orderChanged || serviceList.length !== items.length) {
        setServiceListLocal(items as IServiceListItem[]);
        onUpdate?.(items as IServiceListItem[]);
      }
    }
  };

  const handleServiceClick = (serviceId: number) => {
    setActiveServiceId(serviceId);
    dispatch(setServicesScreenActivePage(EServicesScreenPage.editService));
  };

  return (
    <ServiceListContainerSortable
      list={serviceListLocal}
      setList={handleSetList}
      // ghostClass="dropArea"
      handle=".dragHandle"
      filter=".ignoreDrag"
      preventOnFilter={true}
    >
      {serviceListLocal?.map((serviceItem) => {
        return (
          <ServiceItemContainer
            key={serviceItem.id}
            className="dragHandle"
            onClick={() => handleServiceClick(serviceItem.id)}
          >
            <ServiceItemContent>
              <SymbolDelete
                className="ignoreDrag"
                onClick={(event) => handleDeleteServiceItem({ event, serviceId: serviceItem.id })}
              >
                delete
              </SymbolDelete>
              <ServiceIcon $imgUrl={serviceItem.image_url} />
            </ServiceItemContent>
            <ServiceItemFooter>
              <ServiceItemNameContainer>
                <ServiceItemName>{serviceItem.name}</ServiceItemName>
              </ServiceItemNameContainer>
            </ServiceItemFooter>
          </ServiceItemContainer>
        );
      })}
    </ServiceListContainerSortable>
  );
};
