import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { TRootState, useAppDispatch } from '@/store';
import { ServiceNotAvailable } from '@/features/modules/components/ServiceNotAvailable';
import { ModulesHeading, Module, Modules as ModulesWrapper, ModulesContainer } from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { ROUTES } from '@/shared/lib/const';
import { IModuleRecord } from '@/features/sidebarMenus/types';
import { servicesData } from '@/features/sidebarMenus/stub/servicesData';
import { setCurrentModules } from '../../redux/modules.slice';

export const Modules: FC = () => {
  const { service: serviceName } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const modules = useSelector((state: TRootState) => state.modules.modules);
  const services = useSelector((state: TRootState) => state.services.services);
  const serviceDisabled = useSelector((state: TRootState) => state.modules.disabled);
  const middleMenuOptions = useSelector((state: TRootState) => state.services.middleMenuOptions);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(services));
  }, [services]);

  useEffect(() => {
    if (serviceName) {
      dispatch(setMiddleMenuActiveBtn(serviceName));
      const currentService = servicesData.find((serviceItem) => {
        return serviceItem.external_service_id === serviceName;
      });
      dispatch(setCurrentModules(currentService.modules));
    }
  }, [middleMenuOptions, serviceName]);

  const handleModuleClick = (module: IModuleRecord) => {
    dispatch(setMiddleMenuOptions(modules));
    navigate(
      ROUTES.module.replace(':service', serviceName).replace(':module', module.external_module_id)
    );
  };

  return (
    <>
      {serviceDisabled ? (
        <ServiceNotAvailable />
      ) : (
        <ModulesContainer>
          <ModulesHeading>Доступные модули</ModulesHeading>
          <ModulesWrapper>
            {modules?.map((module) => {
              return (
                <Module key={module.id} onClick={() => handleModuleClick(module)}>
                  {module.name}
                </Module>
              );
            })}
          </ModulesWrapper>
        </ModulesContainer>
      )}
    </>
  );
};
