import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

import { TRootState, useAppDispatch } from '@/store';
import { NotificationTaskContainer } from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { NotificationTaskContent } from './components/NotificationTaskContent';
import {
  fetchNotificationTask,
  fetchNotificationTaskList,
} from '../redux/notificationTasks.actions';
import { createNewNotificationTask } from '../redux/notificationTasks.slice';

const NotificationTask = () => {
  const { notification: taskId } = useParams();
  const dispatch = useAppDispatch();
  const tasks = useSelector((state: TRootState) => state.notificationTasks.tasks);
  const newTask = useSelector((state: TRootState) => state.notificationTasks.newTask);

  const handleCreateNewTask = useCallback(
    debounce((taskId, newTask) => {
      if (taskId === 'new-notification' && !newTask) {
        dispatch(createNewNotificationTask());
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleCreateNewTask(taskId, newTask);
  }, [handleCreateNewTask, taskId, newTask]);

  useEffect(() => {
    if (taskId) {
      dispatch(setMiddleMenuActiveBtn(taskId));
    }
  }, [dispatch, taskId]);

  useEffect(() => {
    if (tasks) {
      const tasksWithName = cloneDeep(tasks).map((task) => {
        task.name = `ID: ${task.id}`;
        return task;
      });
      let middleMenuOptions;
      if (newTask) {
        middleMenuOptions = [...tasksWithName, { id: 'new-notification', key: 'new-notification' }];
      } else {
        middleMenuOptions = tasksWithName;
      }
      dispatch(setMiddleMenuOptions(middleMenuOptions));
    }
  }, [dispatch, tasks, newTask]);

  useEffect(() => {
    let fetchNotificationTaskPromise: any;

    dispatch(fetchNotificationTaskList());
    if (taskId !== 'new-notification') {
      fetchNotificationTaskPromise = dispatch(fetchNotificationTask(taskId));
    }

    return () => {
      fetchNotificationTaskPromise?.abort();
    };
  }, [dispatch, taskId]);

  return (
    <NotificationTaskContainer>
      <NotificationTaskContent />
    </NotificationTaskContainer>
  );
};

export default NotificationTask;
