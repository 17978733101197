import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ClientListWrapper = styled(Box)(({ theme }) => {
  return {
    marginBottom: '20px',

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 140px)',
    },
  };
});
