import { ElementType } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const UploadFileButton = styled(Button)<ButtonProps & { component: ElementType }>(({
  theme,
}) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return `
    padding: 5px !important;
    min-width: 20px !important;
    border: 1px solid ${styledTheme.colors.uploadFileWithTextField.uploadFileButton.border.main} !important;
    width: 37px;
    height: 37px;
    margin-top: 2px !important;

    &:hover {
      border-color: ${styledTheme.colors.uploadFileWithTextField.uploadFileButton.border.hover} !important;
    }
  `;
});
