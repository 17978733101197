import { FC, forwardRef, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { StyledVisibility, StyledVisibilityOff } from './elements';

export const PasswordTextField: FC<OutlinedInputProps> = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <OutlinedInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <StyledVisibilityOff /> : <StyledVisibility />}
          </IconButton>
        </InputAdornment>
      }
      ref={ref}
    />
  );
});
