import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import cloneDeep from 'lodash.clonedeep';
import { DivKit } from '@divkitframework/react';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { toast } from 'react-toastify';

import { CustomSelect } from '@/shared/components/CustomSelect';
import { CustomTextarea } from '@/features/promotions/components/CustomTextarea';
import {
  promotionBtnActionTypeOptionTexts,
  promotionBtnActionTypeOptionValues,
} from '@/features/promotions/const';
import {
  clonePromotion,
  resetNewPromotion,
  setPromotionAtivePage,
  setPromotionButtonActionType,
  setPromotionButtonActionValue,
  setPromotionButtonBackgroundColor,
  setPromotionButtonTextColor,
  setPromotionButtonValue,
  setPromotionCategories,
  setPromotionCondition,
  setPromotionDescription,
  setPromotionFinishDatetime,
  setPromotionFullImage,
  setPromotionListImage,
  setPromotionName,
  setPromotionPublicDatetime,
  setPromotionRequirements,
  setPromotionStartDatetime,
  setPromotionType,
  setPromotionVisualizationDataEmpty,
} from '@/features/promotions/redux/promotions.slice';
import {
  EPromotionItemActionType,
  EPromotionTabPages,
  IPromotionCategory,
} from '@/features/promotions/types';
import { CustomDatePicker } from '@/shared/components/CustomDatePicker';
import { MainButton } from '@/shared/components/MainButton';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { TRootState, useAppDispatch } from '@/store';
import { usePromotionTypesSelectData } from '../hooks/usePromotionTypesSelectData';
import { usePromotionCategoriesSelectData } from '../hooks/usePromotionCategoriesSelectData';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { ColorPickerWithTextField } from '@/shared/components/ColorPickerWithTextField';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import {
  isValidPromotionAsDraft,
  isValidPromotionForPublication,
} from '@/features/promotions/utils';
import {
  fetchPromotionVisualizationData,
  savePromotionItem,
  updateCurrentPromotionItem,
} from '@/features/promotions/redux/promotions.actions';
import { MultipleSelectCheckmarks } from '@/shared/components/MultipleSelectCheckmarks';
import { isPromotionTouched } from '@/features/stories/utils';
import '@divkitframework/divkit/dist/client.css';
import { CustomTextField } from '@/shared/components/CustomTextField';
import Yup from '@/shared/validations';
import { ERequestStatus } from '@/shared/lib/types';
import { showSuccessNotification } from '@/shared/utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().promotionNameValidation(),
  typeId: Yup.string().promotionTypeValidation(),
  categoryIds: Yup.array().promotionCategoryIdsValidation(),
  listImage: Yup.string().externalLinkInputValidation(),
  fullImage: Yup.string().externalLinkInputValidation(),
  description: Yup.string().promotionDescriptionValidation(),
  condition: Yup.string().promotionConditionValidation(),
  requirements: Yup.string().promotionRequirementsValidation(),
  buttonActionType: Yup.string().itemActionTypeValidation(),
  buttonActionValue: Yup.string().externalLinkInputValidation(),
  buttonBackgroundColor: Yup.string().colorInputValidation(),
  buttonTextValue: Yup.string().itemButtonTextValueValidation(),
  buttonTextColor: Yup.string().colorInputValidation(),
  promotionStartDatetime: Yup.string().promotionStartDatetimeValidation(),
  promotionFinishDatetime: Yup.string().promotionFinishDatetimeValidation(),
  promotionPublicationDatetime: Yup.string().promotionPublicationDatetimeValidation(),
});

export const Promotion = () => {
  const dispatch = useAppDispatch();
  const activePage = useSelector((state: TRootState) => state.promotions.promotionsTab.activePage);
  const status = useSelector((state: TRootState) => state.promotions.promotion.status);

  const categories = useSelector((state: TRootState) => state.promotions.promotion.categories);

  const promotionVisualizationData = useSelector(
    (state: TRootState) => state.promotions.promotionVisualizationData
  );
  const { promotionTypeOptionTexts, promotionTypeOptionValues } = usePromotionTypesSelectData();
  const allPromotionTypes = useSelector((state: TRootState) => state.promotions.promotionTypes);
  const { promotionCategoriesOptionTexts, promotionCategoriesOptionValues } =
    usePromotionCategoriesSelectData();
  const allPromotionCategories = useSelector(
    (state: TRootState) => state.promotions.promotionCategories
  );
  const promotion = useSelector((state: TRootState) => state.promotions.promotion);
  const fetchPromotionItemStatus = useSelector(
    (state: TRootState) => state.promotions.fetchPromotionItemStatus
  );

  const isValidPromotionForPublicationMemo = useMemo(() => {
    const isNew = activePage === EPromotionTabPages.newPromotion;
    return isValidPromotionForPublication({ promotion, isNew });
  }, [promotion, activePage]);

  const isSaveForPublicationBtnDisabled = useMemo(() => {
    return fetchPromotionItemStatus === ERequestStatus.pending;
  }, [fetchPromotionItemStatus]);

  const isSaveAsDraftBtnDisabled = useMemo(() => {
    return fetchPromotionItemStatus === ERequestStatus.pending;
  }, [fetchPromotionItemStatus]);

  const categoryIds = useMemo(() => {
    return categories.map((category) => {
      return category.id;
    });
  }, [categories]);

  const [
    shouldCallGetPromotionVisualizationDataWithValidation,
    setShouldCallGetPromotionVisualizationDataWithValidation,
  ] = useState(true);

  const formik = useFormik({
    initialValues: {
      name: '',
      typeId: '',
      categoryIds: [],
      listImage: '',
      fullImage: '',
      description: '',
      condition: '',
      requirements: '',
      buttonActionType: '',
      buttonActionValue: '',
      buttonBackgroundColor: '',
      buttonTextValue: '',
      buttonTextColor: '',
      promotionStartDatetime: '',
      promotionFinishDatetime: '',
      promotionPublicationDatetime: '',
    },
    onSubmit: (values, actions) => {},
    validationSchema,
  });

  useEffect(() => {
    if (!isEmpty(promotion)) {
      formik.setFieldValue('name', promotion.name);
      formik.setFieldValue('typeId', promotion.type?.id);
      formik.setFieldValue('listImage', promotion.list_image);
      formik.setFieldValue('fullImage', promotion.full_image);
      formik.setFieldValue('description', promotion.description);
      formik.setFieldValue('condition', promotion.condition);
      formik.setFieldValue('requirements', promotion.requirements);
      formik.setFieldValue('buttonActionType', promotion.button?.params?.on_click?.action_type);
      formik.setFieldValue('buttonActionValue', promotion.button?.params?.on_click?.action_value);
      formik.setFieldValue('buttonBackgroundColor', promotion.button?.params?.background_color);
      formik.setFieldValue('buttonTextValue', promotion.button?.params?.value);
      formik.setFieldValue('buttonTextColor', promotion.button?.params?.text_color);
      formik.setFieldValue('promotionStartDatetime', promotion.start_datetime);
      formik.setFieldValue('promotionFinishDatetime', promotion.finish_datetime);
      formik.setFieldValue('promotionPublicationDatetime', promotion.public_datetime);
    }
  }, [promotion]);

  useEffect(() => {
    if (categoryIds) {
      formik.setFieldValue('categoryIds', categoryIds);
    }
  }, [categoryIds]);

  const triggerGetPromotionVisualizationDataWithValidation = () => {
    setShouldCallGetPromotionVisualizationDataWithValidation(true);
  };

  useEffect(() => {
    if (shouldCallGetPromotionVisualizationDataWithValidation) {
      const isNew = activePage === EPromotionTabPages.newPromotion;

      if (isValidPromotionForPublication({ promotion, isNew })) {
        dispatch(fetchPromotionVisualizationData());
        setShouldCallGetPromotionVisualizationDataWithValidation(false);
      }
    }
  }, [promotion, shouldCallGetPromotionVisualizationDataWithValidation, activePage, dispatch]);

  const handleCloneBtnClick = () => {
    dispatch(clonePromotion());
    dispatch(setPromotionAtivePage(EPromotionTabPages.newPromotion));
    triggerGetPromotionVisualizationDataWithValidation();
    showSuccessNotification({ message: 'Акция была успешно скопирована' });
  };

  const handleCancelBtnClick = () => {
    dispatch(resetNewPromotion());
    dispatch(setPromotionVisualizationDataEmpty());
    dispatch(setPromotionAtivePage(EPromotionTabPages.promotionList));
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionName(event.target.value));
    formik.setFieldValue('name', event.target.value);
  };
  const handleNameBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handleNameKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerGetPromotionVisualizationDataWithValidation();
    }
  };

  const handlePromotionTypeChange = (value: string) => {
    const typeId = !value ? value : Number(value);
    const selectedType = allPromotionTypes.find((promotionType) => {
      return promotionType.id === typeId;
    });
    dispatch(setPromotionType(selectedType));
    formik.setFieldValue('typeId', typeId);
    triggerGetPromotionVisualizationDataWithValidation();
  };

  const handlePromotionCategoryChange = (categoryIds: (number | string)[]) => {
    const selectedCategories: IPromotionCategory[] = (categoryIds as number[]).map((categoryId) => {
      const category = allPromotionCategories.find((category) => {
        return category.id === categoryId;
      });
      return cloneDeep(category);
    });
    dispatch(setPromotionCategories(selectedCategories));
    formik.setFieldValue('categoryIds', categoryIds);
    triggerGetPromotionVisualizationDataWithValidation();
  };

  const handlePromotionListImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionListImage(event.target.value));
    formik.setFieldValue('listImage', event.target.value);
  };
  const handlePromotionListImageUpload = (url: string) => {
    dispatch(setPromotionListImage(url));
    formik.setFieldValue('listImage', url);
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionListImageBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionListImageKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerGetPromotionVisualizationDataWithValidation();
    }
  };

  const handlePromotionFullImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionFullImage(event.target.value));
    formik.setFieldValue('fullImage', event.target.value);
  };
  const handlePromotionFullImageUpload = (url: string) => {
    dispatch(setPromotionFullImage(url));
    formik.setFieldValue('fullImage', url);
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionFullImageBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionFullImageKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerGetPromotionVisualizationDataWithValidation();
    }
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setPromotionDescription(event.target.value));
    formik.setFieldValue('description', event.target.value);
  };
  const handleDescriptionBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handleConditionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setPromotionCondition(event.target.value));
    formik.setFieldValue('condition', event.target.value);
  };
  const handleConditionBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handleRequirementsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setPromotionRequirements(event.target.value));
    formik.setFieldValue('requirements', event.target.value);
  };
  const handleRequirementsBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };

  const handlePromotionButtonActionTypeChange = (value: string) => {
    dispatch(setPromotionButtonActionType(value));
    formik.setFieldValue('buttonActionType', value);
    triggerGetPromotionVisualizationDataWithValidation();
  };

  const handlePromotionButtonActionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionButtonActionValue(event.target.value));
    formik.setFieldValue('buttonActionValue', event.target.value);
  };
  const handlePromotionButtonActionValueBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionButtonActionValueKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerGetPromotionVisualizationDataWithValidation();
    }
  };

  const handlePromotionButtonBackgroundColorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setPromotionButtonBackgroundColor(event.target.value));
    formik.setFieldValue('buttonBackgroundColor', event.target.value);
  };
  const handlePromotionButtonBackgroundColorFromColorPickerChange = (color: string) => {
    dispatch(setPromotionButtonBackgroundColor(color));
    formik.setFieldValue('buttonBackgroundColor', color);
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionButtonBackgroundColorBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionButtonBackgroundColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerGetPromotionVisualizationDataWithValidation();
    }
  };

  const handlePromotionButtonTextValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionButtonValue(event.target.value));
    formik.setFieldValue('buttonTextValue', event.target.value);
  };
  const handlePromotionButtonTextValueBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionButtonTextValueKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerGetPromotionVisualizationDataWithValidation();
    }
  };

  const handlePromotionButtonTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPromotionButtonTextColor(event.target.value));
    formik.setFieldValue('buttonTextColor', event.target.value);
  };
  const handlePromotionButtonTextColorFromColorPickerChange = (color: string) => {
    dispatch(setPromotionButtonTextColor(color));
    formik.setFieldValue('buttonTextColor', color);
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionButtonTextColorBlur = () => {
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionButtonTextColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerGetPromotionVisualizationDataWithValidation();
    }
  };

  const handlePromotionStartDatetimeChange = (date: string) => {
    dispatch(setPromotionStartDatetime(date));
    formik.setFieldValue('promotionStartDatetime', date);
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionFinishDatetimeChange = (date: string) => {
    dispatch(setPromotionFinishDatetime(date));
    formik.setFieldValue('promotionFinishDatetime', date);
    triggerGetPromotionVisualizationDataWithValidation();
  };
  const handlePromotionPublicationDatetimeChange = (date: string) => {
    dispatch(setPromotionPublicDatetime(date));
    formik.setFieldValue('promotionPublicationDatetime', date);
    triggerGetPromotionVisualizationDataWithValidation();
  };

  const handleSaveBtnClick = () => {
    formik.setTouched({
      name: true,
      typeId: true,
      categoryIds: true,
      listImage: true,
      fullImage: true,
      description: true,
      condition: true,
      requirements: true,
      buttonActionType: true,
      buttonActionValue: true,
      buttonBackgroundColor: true,
      buttonTextValue: true,
      buttonTextColor: true,
      promotionStartDatetime: true,
      promotionFinishDatetime: true,
      promotionPublicationDatetime: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        let promise;
        if (activePage === EPromotionTabPages.newPromotion) {
          promise = dispatch(savePromotionItem(EPromotionItemActionType.readyToPublish)).unwrap();
        } else {
          promise = dispatch(
            updateCurrentPromotionItem(EPromotionItemActionType.readyToPublish)
          ).unwrap();
        }
        promise.then(() => {
          dispatch(resetNewPromotion());
          dispatch(setPromotionAtivePage(EPromotionTabPages.promotionList));
        });
      }
    });
  };

  const handleSaveDraftBtnClick = () => {
    formik.setTouched({
      name: true,
      typeId: true,
      categoryIds: true,
      listImage: true,
      fullImage: true,
      description: true,
      condition: true,
      requirements: true,
      buttonActionType: true,
      buttonActionValue: true,
      buttonBackgroundColor: true,
      buttonTextValue: true,
      buttonTextColor: true,
      promotionStartDatetime: true,
      promotionFinishDatetime: true,
      promotionPublicationDatetime: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        let promise;
        if (activePage === EPromotionTabPages.newPromotion) {
          promise = dispatch(savePromotionItem(EPromotionItemActionType.draft)).unwrap();
        } else {
          promise = dispatch(updateCurrentPromotionItem(EPromotionItemActionType.draft)).unwrap();
        }
        promise.then(() => {
          dispatch(resetNewPromotion());
          dispatch(setPromotionAtivePage(EPromotionTabPages.promotionList));
        });
      }
    });
  };

  return (
    <>
      <Stack spacing={2} direction="row">
        <Stack spacing={2} sx={{ position: 'relative', top: '-10px' }} direction="row">
          <Stack sx={{ minWidth: '300px' }}>
            <CustomTextField
              label="ID"
              size="small"
              sx={{ mb: '6px', mt: '8px', flexGrow: null, flexBasis: null }}
              value={promotion?.id || ''}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              displayCopyBtn
            />
            <TextField
              sx={{ mb: formik.touched.name && !!formik.errors.name ? '7px' : 2, mt: '10px' }}
              label="Название"
              type="text"
              size="small"
              value={formik.values.name}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              onKeyUp={handleNameKeyUp}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
            <CustomSelect
              label="Тип акции"
              optionValues={promotionTypeOptionValues}
              optionTexts={promotionTypeOptionTexts}
              sx={{
                maxWidth: '300px',
                mb: formik.touched.typeId && !!formik.errors.typeId ? '7px' : 2,
                flexGrow: null,
                flexBasis: null,
              }}
              value={formik.values.typeId}
              onChange={handlePromotionTypeChange}
              error={formik.touched.typeId && !!formik.errors.typeId}
              helperText={formik.touched.typeId && formik.errors.typeId}
            />
            <MultipleSelectCheckmarks
              label="Категория"
              optionValues={promotionCategoriesOptionValues}
              optionTexts={promotionCategoriesOptionTexts}
              sx={{
                maxWidth: '300px',
                mb: formik.touched.categoryIds && !!formik.errors.categoryIds ? '7px' : 2,
                flexGrow: null,
                flexBasis: null,
              }}
              value={formik.values.categoryIds}
              onChange={handlePromotionCategoryChange}
              error={formik.touched.categoryIds && !!formik.errors.categoryIds}
              helperText={formik.touched.categoryIds && formik.errors.categoryIds}
            />
            <CustomTextField
              label="Статус"
              size="small"
              sx={{ maxWidth: '300px', mb: 2, flexGrow: null, flexBasis: null }}
              value={status?.key || ''}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
            <UploadFileWithTextField
              label="Изображение для списка"
              type="text"
              size="small"
              sx={{ mb: formik.touched.listImage && !!formik.errors.listImage ? '7px' : 2 }}
              value={formik.values.listImage}
              onChange={handlePromotionListImageChange}
              handleFileUpload={handlePromotionListImageUpload}
              onBlur={handlePromotionListImageBlur}
              onKeyUp={handlePromotionListImageKeyUp}
              error={formik.touched.listImage && !!formik.errors.listImage}
              helperText={formik.touched.listImage && formik.errors.listImage}
            />
            <UploadFileWithTextField
              label="Основное изображение"
              type="text"
              size="small"
              sx={{ mb: formik.touched.fullImage && !!formik.errors.fullImage ? '7px' : 2 }}
              value={formik.values.fullImage}
              onChange={handlePromotionFullImageChange}
              handleFileUpload={handlePromotionFullImageUpload}
              onBlur={handlePromotionFullImageBlur}
              onKeyUp={handlePromotionFullImageKeyUp}
              error={formik.touched.fullImage && !!formik.errors.fullImage}
              helperText={formik.touched.fullImage && formik.errors.fullImage}
            />
            <CustomTextarea
              label="Описание акции"
              rows={3}
              ariaLabel="Promotion description"
              placeholder="Описание акции"
              sx={{ mb: formik.touched.description && !!formik.errors.description ? '7px' : 2 }}
              value={formik.values.description}
              onChange={handleDescriptionChange}
              onBlur={handleDescriptionBlur}
              error={formik.touched.description && !!formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
            <CustomTextarea
              label="Условие акции"
              rows={3}
              ariaLabel="Promotion condition"
              placeholder="Условие акции"
              sx={{ mb: formik.touched.condition && !!formik.errors.condition ? '7px' : 2 }}
              value={formik.values.condition}
              onChange={handleConditionChange}
              onBlur={handleConditionBlur}
              error={formik.touched.condition && !!formik.errors.condition}
              helperText={formik.touched.condition && formik.errors.condition}
            />
            <CustomTextarea
              label="Требования акции"
              rows={3}
              ariaLabel="Promotion requirements"
              placeholder="Требования акции"
              value={formik.values.requirements}
              onChange={handleRequirementsChange}
              onBlur={handleRequirementsBlur}
              error={formik.touched.requirements && !!formik.errors.requirements}
              helperText={formik.touched.requirements && formik.errors.requirements}
            />
          </Stack>

          <Stack sx={{ minWidth: '300px' }}>
            <TextFieldGroup sx={{ mb: '16px', pt: '0px' }} title="Кнопка">
              <Stack>
                <TextFieldGroup marginBottom="16px" title="Действие">
                  <Stack>
                    <CustomSelect
                      label="Тип"
                      optionValues={promotionBtnActionTypeOptionValues}
                      optionTexts={promotionBtnActionTypeOptionTexts}
                      sx={{
                        mb:
                          formik.touched.buttonActionType && !!formik.errors.buttonActionType
                            ? '7px'
                            : 2,
                        maxWidth: '300px',
                        flexGrow: null,
                        flexBasis: null,
                      }}
                      value={formik.values.buttonActionType}
                      onChange={handlePromotionButtonActionTypeChange}
                      error={formik.touched.buttonActionType && !!formik.errors.buttonActionType}
                      helperText={formik.touched.buttonActionType && formik.errors.buttonActionType}
                    />
                    <TextField
                      label="Ссылка"
                      type="text"
                      size="small"
                      sx={{
                        mb:
                          formik.touched.buttonActionValue && !!formik.errors.buttonActionValue
                            ? '-3px'
                            : null,
                      }}
                      value={formik.values.buttonActionValue}
                      onChange={handlePromotionButtonActionValueChange}
                      onBlur={handlePromotionButtonActionValueBlur}
                      onKeyUp={handlePromotionButtonActionValueKeyUp}
                      error={formik.touched.buttonActionValue && !!formik.errors.buttonActionValue}
                      helperText={
                        formik.touched.buttonActionValue && formik.errors.buttonActionValue
                      }
                    />
                  </Stack>
                </TextFieldGroup>
                <ColorPickerWithTextField
                  label="Цвет фона"
                  type="text"
                  size="small"
                  sx={{
                    mb:
                      formik.touched.buttonBackgroundColor && !!formik.errors.buttonBackgroundColor
                        ? '7px'
                        : 2,
                  }}
                  value={formik.values.buttonBackgroundColor}
                  onInput={handlePromotionButtonBackgroundColorChange}
                  handleColorChange={handlePromotionButtonBackgroundColorFromColorPickerChange}
                  onBlur={handlePromotionButtonBackgroundColorBlur}
                  onKeyUp={handlePromotionButtonBackgroundColorKeyUp}
                  error={
                    formik.touched.buttonBackgroundColor && !!formik.errors.buttonBackgroundColor
                  }
                  helperText={
                    formik.touched.buttonBackgroundColor && formik.errors.buttonBackgroundColor
                  }
                />
                <TextField
                  label="Текст"
                  type="text"
                  size="small"
                  sx={{
                    mb:
                      formik.touched.buttonTextValue && !!formik.errors.buttonTextValue ? '7px' : 2,
                  }}
                  value={formik.values.buttonTextValue}
                  onChange={handlePromotionButtonTextValueChange}
                  onBlur={handlePromotionButtonTextValueBlur}
                  onKeyUp={handlePromotionButtonTextValueKeyUp}
                  error={formik.touched.buttonTextValue && !!formik.errors.buttonTextValue}
                  helperText={formik.touched.buttonTextValue && formik.errors.buttonTextValue}
                />
                <ColorPickerWithTextField
                  label="Цвет текста"
                  type="text"
                  size="small"
                  sx={{ marginBottom: '0' }}
                  value={formik.values.buttonTextColor}
                  onInput={handlePromotionButtonTextColorChange}
                  handleColorChange={handlePromotionButtonTextColorFromColorPickerChange}
                  onBlur={handlePromotionButtonTextColorBlur}
                  onKeyUp={handlePromotionButtonTextColorKeyUp}
                  error={formik.touched.buttonTextColor && !!formik.errors.buttonTextColor}
                  helperText={formik.touched.buttonTextColor && formik.errors.buttonTextColor}
                />
              </Stack>
            </TextFieldGroup>
            <CustomDatePicker
              sx={{
                mb:
                  formik.touched.promotionStartDatetime && !!formik.errors.promotionStartDatetime
                    ? 0
                    : '8px',
              }}
              label="Дата старта"
              value={formik.values.promotionStartDatetime}
              onChange={handlePromotionStartDatetimeChange}
              error={
                formik.touched.promotionStartDatetime && !!formik.errors.promotionStartDatetime
              }
              helperText={
                formik.touched.promotionStartDatetime && formik.errors.promotionStartDatetime
              }
            />
            <CustomDatePicker
              sx={{
                mb:
                  formik.touched.promotionFinishDatetime && !!formik.errors.promotionFinishDatetime
                    ? 0
                    : '8px',
              }}
              label="Дата окончания"
              value={formik.values.promotionFinishDatetime}
              onChange={handlePromotionFinishDatetimeChange}
              error={
                formik.touched.promotionFinishDatetime && !!formik.errors.promotionFinishDatetime
              }
              helperText={
                formik.touched.promotionFinishDatetime && formik.errors.promotionFinishDatetime
              }
            />
            <CustomDatePicker
              sx={{
                mb:
                  formik.touched.promotionPublicationDatetime &&
                  !!formik.errors.promotionPublicationDatetime
                    ? 0
                    : '8px',
              }}
              label="Дата публикации"
              value={formik.values.promotionPublicationDatetime}
              onChange={handlePromotionPublicationDatetimeChange}
              error={
                formik.touched.promotionPublicationDatetime &&
                !!formik.errors.promotionPublicationDatetime
              }
              helperText={
                formik.touched.promotionPublicationDatetime &&
                formik.errors.promotionPublicationDatetime
              }
            />
            <MainButton
              sx={{ mb: 2, width: '150px' }}
              disabled={isSaveForPublicationBtnDisabled}
              onClick={handleSaveBtnClick}
            >
              Сохранить
            </MainButton>
            <SecondaryButton
              sx={{ mb: 2, width: '240px' }}
              disabled={isSaveAsDraftBtnDisabled}
              onClick={handleSaveDraftBtnClick}
            >
              Сохранить как черновик
            </SecondaryButton>
            <SecondaryButton sx={{ mb: 2, width: '150px' }} onClick={handleCloneBtnClick}>
              Копировать
            </SecondaryButton>
            <SecondaryButton sx={{ width: '150px' }} onClick={handleCancelBtnClick}>
              Отмена
            </SecondaryButton>
          </Stack>
        </Stack>
        <Box sx={{ position: 'relative', top: '-10px' }}>
          <TextFieldGroup title="Картинка для списка">
            {promotionVisualizationData.short_promotion ? (
              <Box
                sx={{
                  width: '400px',
                  background: '#ffffff',
                  border: '1px solid orange',
                }}
              >
                <DivKit id="short-promotion" json={promotionVisualizationData.short_promotion} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '400px',
                  height: '192px',
                }}
              >
                {isValidPromotionForPublicationMemo ? (
                  <CircularProgress
                    // @ts-ignore: Unreachable code error
                    color="textMain"
                    size="40px"
                  />
                ) : null}
              </Box>
            )}
          </TextFieldGroup>
          <TextFieldGroup marginBottom="16px" title="Полноразмерная картинка">
            {promotionVisualizationData.full_promotion ? (
              <Box
                sx={{
                  width: '400px',
                  background: '#ffffff',
                  border: '1px solid orange',
                }}
              >
                <DivKit id="full-promotion" json={promotionVisualizationData.full_promotion} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '400px',
                  height: '580px',
                }}
              >
                {isValidPromotionForPublicationMemo ? (
                  <CircularProgress
                    // @ts-ignore: Unreachable code error
                    color="textMain"
                    size="34px"
                  />
                ) : null}
              </Box>
            )}
          </TextFieldGroup>
        </Box>
      </Stack>
    </>
  );
};
