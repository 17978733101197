import styled from 'styled-components';

import logo from '@/assets/icons/logo/logo.svg?react';

export const Logo = styled(logo)`
  position: relative;
  display: block;
  height: 37px;
  width: 24px;
  color: ${({ theme }) => theme.colors.logo};
  padding-top: 10px;
  margin-right: 6px;
  user-select: none;
`;
