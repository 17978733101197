import { styled } from '@mui/system';
import { Button } from '@mui/material';

import DarkThemeSvg from '@/assets/icons/themes/dark-theme.svg';
import LightThemeSvg from '@/assets/icons/themes/light-theme.svg';
import { EMuiThemeMode } from '@/app/types';

export const ThemeBtn = styled(Button)<{ $theme: EMuiThemeMode }>`
  display: flex;
  height: 32px;
  min-height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
  border: none;
  //prettier-ignore
  background-image: url("${({ $theme }) => {
    return $theme === EMuiThemeMode.dark ? DarkThemeSvg : LightThemeSvg;
  }}");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: ${({ $theme }) => ($theme === EMuiThemeMode.dark ? '#003892' : '#001e3c')};
  transition: background-color 300ms linear;
  cursor: pointer;
`;
