import { Box } from '@mui/material';
import { styled } from 'styled-components';

export const ModalContentWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.popup.background};
`;
