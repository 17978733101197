import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocation, matchPath, useNavigate, useParams } from 'react-router-dom';

import { SidebarContent, SidebarWrapper } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import { MenuButton } from './MenuButton';
import { IModuleRecord, IServiceRecord } from '@/features/sidebarMenus/types';
import { setMiddleMenuActiveBtn, setModules } from '@/features/sidebarMenus/redux/services.slice';
import { getServices } from '@/features/sidebarMenus/redux/services.actions';
import { ROUTES } from '@/shared/lib/const';
import { BackButton } from './BackButton';
import { getPrevUri } from '@/features/sidebarMenus/utils/getPrevUri';
import {
  setActiveSlideIdInSlidePage,
  setActiveStorySequenceItemById,
} from '@/features/stories/redux/stories.slice';
import { IScenario, ISlide, IStory, IStorySequenceItem } from '@/features/stories/types';
import { IBannerScenarioItem } from '@/features/banners/types';
import { INotificationTaskListItem } from '@/features/notificationTasks/types';
import { fetchScenarios } from '@/features/stories/redux/stories.actions';
import { fetchPromotionItems } from '@/features/promotions/redux/promotions.actions';
import { fetchBannerScenarios } from '@/features/banners/redux/scenarios.actions';
import { fetchNotificationTaskList } from '@/features/notificationTasks/redux/notificationTasks.actions';
import { IServiceListVersionsItem } from '@/features/servicesScreen/types';
import { fetchServicesScreenVersionList } from '@/features/servicesScreen/redux/servicesScreen.actions';

export const MiddleMenu = () => {
  const { scenario } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const middleMenuOptions = useSelector((state: TRootState) => state.services.middleMenuOptions);
  const middleMenuActiveBtn = useSelector(
    (state: TRootState) => state.services.middleMenuActiveBtn
  );
  const dispatch = useAppDispatch();
  const isServicePath = useMemo(() => {
    return matchPath(ROUTES.service, pathname);
  }, [pathname]);

  useEffect(() => {
    if (matchPath(ROUTES.root, pathname)) {
      dispatch(getServices());
    }
  }, []);

  useEffect(() => {
    if (matchPath(ROUTES.root, pathname) && !!middleMenuOptions && middleMenuOptions.length > 0) {
      dispatch(setMiddleMenuActiveBtn((middleMenuOptions[0] as IServiceRecord).name));
      navigate(ROUTES.service.replace(':service', (middleMenuOptions[0] as IServiceRecord).name), {
        state: { from: location },
      });
    }
  }, [middleMenuOptions, pathname]);

  const handleBtnClick = (
    option:
      | IServiceRecord
      | IModuleRecord
      | IScenario
      | IStorySequenceItem
      | ISlide
      | IBannerScenarioItem
      | INotificationTaskListItem
      | IServiceListVersionsItem
  ) => {
    const decodedPathname = decodeURIComponent(pathname);

    const setMiddleMenuActiveBtnWrapper = () => {
      dispatch(
        setMiddleMenuActiveBtn(
          (option as IServiceRecord).external_service_id ||
            (option as IModuleRecord).external_module_id ||
            (option as IStorySequenceItem).external_story_id ||
            (option as ISlide).external_slide_id ||
            (
              option as
                | IServiceRecord
                | IModuleRecord
                | IBannerScenarioItem
                | INotificationTaskListItem
            ).id?.toString() ||
            (option as IServiceListVersionsItem).version
        )
      );
    };
    const { isShowStoryFormErrors, ...rest } = location.state || {};
    const locationState = rest;

    if (matchPath(ROUTES.service, decodedPathname)) {
      setMiddleMenuActiveBtnWrapper();
      navigate(ROUTES.service.replace(':service', (option as IServiceRecord).external_service_id), {
        state: {
          state: { ...locationState, from: { pathname: decodedPathname } },
        },
      });
    }
    if (matchPath(ROUTES.module, decodedPathname)) {
      const { params } = matchPath(ROUTES.module, decodedPathname);
      const moduleName = (option as IModuleRecord).external_module_id;
      if (params.service === 'Мобильные приложения') {
        let dispatchPromise;
        if (moduleName === 'Сторис') {
          dispatchPromise = dispatch(fetchScenarios());
        } else if (moduleName === 'Акции') {
          dispatchPromise = dispatch(fetchPromotionItems());
        } else if (moduleName === 'Баннеры') {
          dispatchPromise = dispatch(fetchBannerScenarios());
        } else if (moduleName === 'Отправка пушей') {
          dispatchPromise = dispatch(fetchNotificationTaskList());
        } else if (moduleName === 'Экран сервисы') {
          dispatchPromise = dispatch(fetchServicesScreenVersionList());
        }
        dispatchPromise.unwrap().then(() => {
          setMiddleMenuActiveBtnWrapper();
          navigate(
            ROUTES.module.replace(':service', params.service).replace(':module', moduleName),
            {
              state: { ...locationState, from: { pathname: decodedPathname } },
            }
          );
        });
      } else {
        setMiddleMenuActiveBtnWrapper();
        navigate(ROUTES.module.replace(':service', params.service).replace(':module', moduleName), {
          state: { ...locationState, from: { pathname: locationState.pathname } },
        });
      }
    }
    if (matchPath(ROUTES.storyScenario, decodeURIComponent(decodedPathname))) {
      const { params } = matchPath(ROUTES.storyScenario, decodedPathname);
      setMiddleMenuActiveBtnWrapper();
      navigate(
        ROUTES.storyScenario
          .replace(':service', params.service)
          .replace(':module', params.module)
          .replace(':scenario', (option as IScenario).external_scenario_id),
        {
          state: { ...locationState, from: { pathname: decodedPathname } },
        }
      );
    }
    if (matchPath(ROUTES.story, decodedPathname)) {
      let storyId;
      if ((option as IStorySequenceItem).isNewStory) {
        dispatch(setActiveStorySequenceItemById({ isNewStory: true }));
        storyId = 'new_story';
      } else {
        dispatch(
          setActiveStorySequenceItemById({
            storyId: (option as IStorySequenceItem).external_story_id,
            isNewStory: false,
          })
        );
        storyId = (option as IStorySequenceItem).external_story_id;
      }
      const { params } = matchPath(ROUTES.story, decodedPathname);
      setMiddleMenuActiveBtnWrapper();
      navigate(
        ROUTES.story
          .replace(':service', params.service)
          .replace(':module', params.module)
          .replace(':scenario', params.scenario)
          .replace(':story', storyId),
        {
          state: { ...locationState, from: { pathname: decodedPathname } },
        }
      );
    }
    if (matchPath(ROUTES.slide, decodedPathname)) {
      let slideId;
      if ((option as ISlide).isNewSlide) {
        slideId = 'new_slide';
      } else {
        slideId = (option as ISlide).external_slide_id;
      }
      const { params } = matchPath(ROUTES.slide, decodedPathname);
      setMiddleMenuActiveBtnWrapper();
      navigate(
        ROUTES.slide
          .replace(':service', params.service)
          .replace(':module', params.module)
          .replace(':scenario', params.scenario)
          .replace(':story', params.story)
          .replace(':slide', slideId),
        {
          state: { isShowStoryFormErrors, ...locationState, from: { pathname: decodedPathname } },
        }
      );
      dispatch(setActiveSlideIdInSlidePage(slideId));
    }
    if (matchPath(ROUTES.bannerScenario, decodedPathname)) {
      const { params } = matchPath(ROUTES.bannerScenario, decodedPathname);
      setMiddleMenuActiveBtnWrapper();
      navigate(
        ROUTES.bannerScenario
          .replace(':service', params.service)
          .replace(':module', params.module)
          .replace(':scenario', (option as IBannerScenarioItem).id.toString()),
        {
          state: { ...locationState, from: { pathname: decodedPathname } },
        }
      );
    }
    if (matchPath(ROUTES.banner, decodedPathname)) {
      const { params } = matchPath(ROUTES.banner, decodedPathname);
      setMiddleMenuActiveBtnWrapper();
      navigate(
        ROUTES.banner
          .replace(':service', params.service)
          .replace(':module', params.module)
          .replace(':scenario', params.scenario)
          .replace(':banner', (option as IBannerScenarioItem).id.toString()),
        {
          state: { ...locationState, from: { pathname: decodedPathname } },
        }
      );
    }
    if (matchPath(ROUTES.notificationTask, decodedPathname)) {
      const { params } = matchPath(ROUTES.notificationTask, decodedPathname);
      setMiddleMenuActiveBtnWrapper();
      navigate(
        ROUTES.notificationTask
          .replace(':service', params.service)
          .replace(':module', params.module)
          .replace(':notification', (option as INotificationTaskListItem).id.toString()),
        {
          state: { ...locationState, from: { pathname: decodedPathname } },
        }
      );
    }
    if (matchPath(ROUTES.servicesScreen.version, decodedPathname)) {
      const { params } = matchPath(ROUTES.servicesScreen.version, decodedPathname);
      setMiddleMenuActiveBtnWrapper();
      navigate(
        ROUTES.servicesScreen.version
          .replace(':service', params.service)
          .replace(':module', params.module)
          .replace(':version', (option as IServiceListVersionsItem).version.toString()),
        {
          state: { ...locationState, from: { pathname: decodedPathname } },
        }
      );
    }
  };

  const handleBackBtnClick = () => {
    const locationState = location.state;
    navigate(getPrevUri(pathname), {
      state: {
        ...locationState,
        from: location,
      },
    });
  };

  return (
    <SidebarWrapper>
      <Scrollbars>
        <SidebarContent>
          {!isServicePath && <BackButton onClick={handleBackBtnClick} />}
          {middleMenuOptions?.map((option) => {
            let optionId;
            if ((option as IStorySequenceItem)?.isNewStory) {
              optionId = 'new_story';
            } else if ((option as ISlide)?.isNewSlide) {
              optionId = 'new_slide';
            } else {
              optionId =
                (option as IServiceRecord)?.external_service_id ||
                (option as IModuleRecord)?.external_module_id ||
                (option as IStorySequenceItem)?.external_story_id ||
                (option as IScenario)?.external_scenario_id ||
                (option as ISlide)?.external_slide_id ||
                (option as IBannerScenarioItem)?.id ||
                (option as unknown as IServiceListVersionsItem)?.version;
            }

            let text;
            if ((option as ISlide)?.isNewSlide) {
              text = 'Новый слайд';
            } else if ((option as IStorySequenceItem)?.isNewStory) {
              text = 'Новая стори';
            } else {
              if ((option as IBannerScenarioItem)?.key && (option as IBannerScenarioItem)?.name) {
                text = `${(option as IBannerScenarioItem)?.key} / ${(option as IBannerScenarioItem)
                  ?.name}`;
              } else {
                text =
                  (
                    option as
                      | IServiceRecord
                      | IModuleRecord
                      | IStorySequenceItem
                      | ISlide
                      | IBannerScenarioItem
                  )?.name ||
                  (option as IScenario)?.external_scenario_id ||
                  (option as IBannerScenarioItem)?.key;
              }
            }

            return (
              <MenuButton
                key={optionId}
                icon={(option as IServiceRecord)?.logo_url}
                isActive={middleMenuActiveBtn === optionId?.toString()}
                text={text}
                onClick={() => handleBtnClick(option)}
              ></MenuButton>
            );
          })}
        </SidebarContent>
      </Scrollbars>
    </SidebarWrapper>
  );
};
