import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return `
    &.MuiDataGrid-root .MuiDataGrid-container--top [role=row] {
      background-color: transparent !important;
    }
  `;
});
