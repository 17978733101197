import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TRootState, useAppDispatch } from '@/store';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { CustomTabs } from './components/CustomTabs';
import { PromotionsTab } from './PromotionsTab';
import { IModuleRecord } from '@/features/sidebarMenus/types';
import { PromotionCollectionTab } from './PromotionCollectionsTab';
import { CatalogTab } from './CatalogTab';

const tabTitles = ['Акции', 'Подборки', 'Каталог'];
const tabsContent = [PromotionsTab, PromotionCollectionTab, CatalogTab];

const Promotions = () => {
  const dispatch = useAppDispatch();
  const modules = useSelector((state: TRootState) => state.services.modules);
  const middleMenuOptions = useSelector((state: TRootState) => state.services.middleMenuOptions);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(modules));
  }, [modules]);

  useEffect(() => {
    if (!!middleMenuOptions && middleMenuOptions.length > 0) {
      dispatch(setMiddleMenuActiveBtn((middleMenuOptions[1] as IModuleRecord).external_module_id));
    }
  }, [middleMenuOptions]);

  return <CustomTabs tabTitles={tabTitles} tabsContent={tabsContent} />;
};

export default Promotions;
