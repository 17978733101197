import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TRootState, useAppDispatch } from '@/store';
import { CircularProgressWrapper, VersionContainer } from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { ServiceScreenVersionContent } from './components/ServiceScreenVersionContent';
import {
  fetchServicesScreenServiceList,
  fetchServicesScreenVersionList,
} from '../redux/servicesScreen.actions';
import { CircularProgress } from '@mui/material';
import { ERequestStatus } from '@/shared/lib/types';
import {
  EServicesScreenPage,
  EServicesScreenServiceActionType,
  IClientServiceListItem,
  ICreateServiceServiceData,
  IDeepLinkListItem,
  IServiceListItem,
  IServicesScreenServiceData,
} from '@/features/servicesScreen/types';
import AddService from './components/AddService';
import { setServicesScreenActivePage } from '@/features/servicesScreen/redux/servicesScreen.slice';
import EditService from './components/EditService';
import { usePrevious } from '@/shared/hooks/usePrevious';
import cloneDeep from 'lodash.clonedeep';
import { showSuccessNotification } from '@/shared/utils';

const ServicesScreenVersion = () => {
  const { version } = useParams();
  const dispatch = useAppDispatch();
  const serviceListVersionsData = useSelector(
    (state: TRootState) => state.servicesScreen.serviceListVersionsData
  );
  const fetchServicesScreenServiceListStatus = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenServiceListStatus
  );
  const fetchServicesScreenServiceBulkRequest = useSelector(
    (state: TRootState) => state.servicesScreen.fetchServicesScreenServiceBulkRequest
  );
  const activePage = useSelector((state: TRootState) => state.servicesScreen.activePage);
  const serviceListData = useSelector((state: TRootState) => state.servicesScreen.serviceListData);
  const [editServiceScreenServiceId, setEditServiceScreenServiceId] = useState<number>();
  const [servicesLocal, setServicesLocal] = useState<IServiceListItem[]>([]);
  const [servicesNameLocal, setServicesNameLocal] = useState<string>('');
  const previousServices: IServiceListItem[] = usePrevious(servicesLocal);
  const [isShowLoaderLocalState, setIsShowLoaderLocalState] = useState<boolean>(false);

  const isShowLoader = useMemo(() => {
    return (
      fetchServicesScreenServiceListStatus === ERequestStatus.pending ||
      fetchServicesScreenServiceListStatus === ERequestStatus.idle ||
      fetchServicesScreenServiceBulkRequest === ERequestStatus.pending ||
      isShowLoaderLocalState
    );
  }, [
    fetchServicesScreenServiceListStatus,
    fetchServicesScreenServiceBulkRequest,
    isShowLoaderLocalState,
  ]);

  useEffect(() => {
    let fetchServicesScreenServiceListPromise: any;

    dispatch(fetchServicesScreenVersionList());
    if (version !== undefined) {
      fetchServicesScreenServiceListPromise = dispatch(fetchServicesScreenServiceList(version));
    }
    return () => {
      fetchServicesScreenServiceListPromise?.abort();
    };
  }, [dispatch, version]);

  useEffect(() => {
    dispatch(setServicesScreenActivePage(EServicesScreenPage.serviceList));
  }, [version]);

  useEffect(() => {
    if (serviceListData?.services) {
      setServicesLocal(serviceListData?.services);
    }
  }, [serviceListData?.services]);

  useEffect(() => {
    if (serviceListData?.name) {
      setServicesNameLocal(serviceListData.name);
    }
  }, [serviceListData?.name]);

  const updateService = (serviceItem: IClientServiceListItem) => {
    const servicesLocalClone = cloneDeep(servicesLocal);
    servicesLocalClone?.find((currentService) => {
      if (currentService.external_id === serviceItem.service.external_id) {
        currentService.id = serviceItem.service.id;
        currentService.image_url = serviceItem.service.image_url;
        currentService.name = serviceItem.service.name;
        return true;
      }
      return false;
    });
    setServicesLocal(servicesLocalClone);
  };

  const bulkUpdateServices = (data: IServicesScreenServiceData[]) => {
    const servicesLocalClone = cloneDeep(servicesLocal);
    data.forEach((service: IServicesScreenServiceData) => {
      servicesLocalClone?.forEach((currentService) => {
        if (currentService.external_id === service.service.external_id) {
          currentService.id = service.service.id;
          currentService.image_url = service.service.image_url;
          currentService.name = service.service.name;
        }
      });
    });
    setServicesLocal(servicesLocalClone);
    setIsShowLoaderLocalState(false);
    showSuccessNotification({
      message: 'Все иконки сервисов успешно обновлены',
    });
  };

  const addSeviceToServicesLocal = (serviceItem: ICreateServiceServiceData) => {
    const newService: IServiceListItem = {
      id: serviceItem.id,
      external_id: serviceItem.external_id,
      image_url: serviceItem.image_url,
      name: serviceItem.name,
    };

    const servicesLocalClone = cloneDeep(servicesLocal);
    servicesLocalClone.push(newService);

    setServicesLocal(servicesLocalClone);
  };

  return (
    <VersionContainer>
      {isShowLoader ? (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      ) : (
        (activePage === EServicesScreenPage.serviceList && (
          <ServiceScreenVersionContent
            setActiveServiceId={setEditServiceScreenServiceId}
            servicesLocal={servicesLocal}
            setServicesLocal={setServicesLocal}
            servicesNameLocal={servicesNameLocal}
            setServicesNameLocal={setServicesNameLocal}
            previousServices={previousServices}
            bulkUpdateServices={bulkUpdateServices}
            setIsShowLoader={setIsShowLoaderLocalState}
          />
        )) ||
        (activePage === EServicesScreenPage.addService && (
          <AddService
            addSeviceToServicesLocal={addSeviceToServicesLocal}
            servicesLocal={servicesLocal}
          />
        )) ||
        (activePage === EServicesScreenPage.editService && (
          <EditService serviceId={editServiceScreenServiceId} updateService={updateService} />
        ))
      )}
    </VersionContainer>
  );
};

export default ServicesScreenVersion;
