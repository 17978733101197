import styled from 'styled-components';

export const Symbol = styled.span.attrs(() => ({
  className: 'material-symbols-rounded',
}))`
  font-size: 25px;
  color: ${({ theme }) => theme.colors.actionButton.main};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.actionButton.hover};
  }
`;
