import { Box } from '@mui/material';
import styled from 'styled-components';

export const ModalContentWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700;
  background-color: ${({ theme }) => theme.colors.popup.background};
  padding: 24px;
  height: 580px;
  min-height: 390px;
  max-height: calc(100vh - 60px);
  border-radius: 5px;
`;
