import { Button } from '@mui/material';
import styled from 'styled-components';

export const ConfirmBtn = styled(Button)`
  &.remove-slide-modal-btn {
    width: 100%;
    color: ${({ theme }) => theme.colors.removeSlideModal.confirmButton.text};
    background-color: ${({ theme }) => theme.colors.removeSlideModal.confirmButton.background.main};
    margin-bottom: 10px;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.removeSlideModal.confirmButton.background.hover};
      box-shadow:
        0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;
