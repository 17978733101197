import { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Scrollbars from 'react-custom-scrollbars';

import { IClientListRecord } from '@/shared/lib/types';
import { StyledListItemText } from './elements';

interface IClientListProps {
  clientListData: IClientListRecord[];
  onListItemClick: (client: IClientListRecord) => void;
}

export const ClientList: FC<IClientListProps> = ({ clientListData, onListItemClick }) => {
  return (
    <Scrollbars style={{ width: '100%', height: '100%', minHeight: '300px' }}>
      <List
        sx={{
          height: '100%',
          minHeight: '300px',
        }}
      >
        {clientListData?.map((client) => (
          <ListItem key={client.id} onClick={() => onListItemClick(client)}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar alt="Логотип клиента" src={client.logo_url} />
              </ListItemAvatar>
              <StyledListItemText primary={client.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Scrollbars>
  );
};
