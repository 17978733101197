import { styled } from '@mui/system';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const MainContent = styled('div')(({ theme }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return {
    color: styledTheme.colors.text.auth,
    fontWeight: '600',
    margin: '0 auto',
    backgroundColor: styledTheme.colors.loginPage.controlsBlock.background,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 5px 0px',
    padding: '15px 15px 5px 15px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minHeight: '100svh',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      borderRadius: '8px',
    },
  };
});
