import { EMuiThemeMode } from '../types';

export const styledLightTheme = {
  colors: {
    background: {
      main: '#ffffff',
      module: {
        main: '#ffffff',
        border: 'rgba(0, 0, 0, 0.1)',
        hover: 'rgba(255, 255, 255, 0.2)',
        disabled: '#9c9c9f',
      },
    },
    borders: {
      main: '#494951',
    },
    text: {
      main: 'rgba(0, 0, 0, 0.87)',
      disabled: 'rgba(255, 255, 255, 0.2)',
      auth: 'rgba(234, 234, 250, 0.9)',
    },
    menu: {
      hover: 'rgba(0, 0, 0, 0.04)',
      active: 'rgba(125, 161, 252, 0.2)',
    },
    button: {
      main: {
        backgroundColor: '#4b7cfc',
        hoverBackgroundColor: '#608cfe',
        backgroundColorDisabled: '#82a4fd',
      },
      secondary: {
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'rgba(255, 255, 255, 0.2)',
        border: 'rgba(0, 0, 0, 0.1)',
        text: 'rgba(0, 0, 0, 0.87)',
      },
    },
    textarea: {
      text: '#fff',
      hover: '#fff',
      placeholderText: 'rgba(255, 255, 255, 0.7)',
    },
    storyViewIndicators: {
      dark: '#686b6d',
    },
    error: '#e61a1a',
    lightGrey: 'rgba(255, 255, 255, 0.23)',
    promotionCollectionItem: {
      border: 'rgba(0, 0, 0, 0.87)',
    },
    fieldset: {
      text: {
        main: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(255 ,255, 255, 0.5)',
      },
    },
    input: {
      disabled: {
        labelText: 'rgba(255, 255, 255, 0.6)',
        inputText: 'rgba(255, 255, 255, 0.7)',
        background: 'rgba(255, 255, 255, 0.1)',
      },
    },
    authLayoutError: '#951f34',
    dispatchersWorkplace: {
      sidebarMenu: {
        background: '#2989CE',
        dispatcherName: '#ffffff',
        dispatcherJobTitleAndRetailNetworkTitle: 'rgba(255, 255, 255, 0.5)',
        menuItem: '#ffffff',
        active: '#2674AC',
        hover: '#2E92DB',
        activeWithHover: '#2a7fbc',
        horizontalLine: 'rgba(255, 255, 255, 0.2)',
      },
      mainContent: {
        background: '#ffffff',
      },
      salepointInfoListDashboard: {
        orderAcceptanceStateIndicator: {
          enabled: '#22AF1F',
          disabled: '#D32F2F',
        },
        orderCollectors: {
          active: '#22AF1F',
          inactive: '#D32F2F',
          text: '#ffffff',
        },
        time: {
          content: {
            red: '#D32F2F',
            orange: '#FF6D00',
            green: '#22AF1F',
            neutral: '#707070',
          },
          background: {
            red: '#FFF1F1',
            orange: '#FFF2E8',
            green: '#ECFFED',
            neutral: '#FFFFFF',
          },
        },
        table: {
          heading: {
            text: 'rgba(0, 0, 0, 0.6)',
          },
          row: {
            borderBottom: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    customSelect: {
      titleText: 'rgba(0, 0, 0, 0.87)',
      subtitleText: 'rgba(0, 0, 0, 0.7)',
    },
    logo: '#ffffff',
    mainMenuSidebar: {
      background: '#1976D2',
      border: '#1976D2',
      text: {
        main: '#ffffff',
      },
    },
    middleMenuSidebar: {
      background: '#ffffff',
      border: 'rgba(0, 0, 0, 0.1)',
      text: {
        main: 'rgba(0, 0, 0, 0.87)',
        disabled: 'rgba(0, 0, 0, 0.2)',
      },
    },
    textFieldGroup: {
      border: 'rgba(0, 0, 0, 0.1)',
    },
    сustomTextField: {
      disabled: {
        background: 'rgba(0, 0, 0, 0.1)',
        label: {
          text: 'rgba(0, 0, 0, 0.6)',
        },
      },
      copyTextButton: {
        border: {
          main: 'rgba(0, 0, 0, 0.23)',
          hover: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    uploadFileWithTextField: {
      uploadFileButton: {
        border: {
          main: 'rgba(0, 0, 0, 0.23)',
          hover: 'rgba(0, 0, 0, 0.87)',
        },
        circularProgress: '#556cd6',
      },
    },
    storyScreen: {
      closeButton: 'rgba(0, 0, 0, 0.87)',
      slide: {
        border: {
          main: 'rgba(0, 0, 0, 0.23)',
          hover: 'rgba(0, 0, 0, 0.87)',
        },
      },
      storyView: {
        border: 'rgba(0, 0, 0, 0.3)',
      },
    },
    slideScreen: {
      closeButton: 'rgba(0, 0, 0, 0.87)',
      slideView: {
        border: 'rgba(0, 0, 0, 0.3)',
      },
    },
    servicesScreen: {
      versionListScreen: {
        navToServicesScreenVersionButton: {
          main: 'rgba(0, 0, 0, 0.87)',
          hover: 'rgba(0, 0, 0, 0.6)',
        },
      },
      versionScreen: {
        serviceItem: {
          border: 'rgba(0, 0, 0, 0.23)',
          boxShadow: 'rgba(0, 0, 0, 0.075)',
        },
      },
    },
    promotions: {
      promotionCollections: {
        promotionList: {
          actionButton: {
            main: 'rgba(0, 0, 0, 0.87)',
            hover: 'rgba(0, 0, 0, 0.6)',
          },
        },
      },
    },
    styledCircularProgress: '#556cd6',
    actionButton: {
      main: 'rgba(0, 0, 0, 0.87)',
      hover: 'rgba(0, 0, 0, 0.6)',
    },
    colorPickerWithTextField: {
      border: {
        main: 'rgba(0, 0, 0, 0.23)',
        hover: 'rgba(0, 0, 0, 0.87)',
      },
    },
    popup: {
      text: 'rgba(0, 0, 0, 0.87)',
      background: '#ffffff',
    },
    removeSlideModal: {
      confirmButton: {
        background: {
          main: '#2b2a33',
          hover: 'rgba(0, 0, 0, 0.6)',
        },
        text: '#ffffff',
      },
      cancelButton: {
        background: {
          main: '#ffffff',
          hover: 'rgba(0, 0, 0, 0.6)',
        },
        text: {
          main: 'rgba(0, 0, 0, 0.87)',
          hover: '#ffffff',
        },
        border: {
          main: 'rgba(0, 0, 0, 0.23)',
          hover: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    loginPage: {
      text: '#ffffff',
      background: '#414141',
      error: '#9c1f42',
      controlsBlock: {
        background: '#1976D2',
      },
      button: {
        border: '#ffffff',
        text: '#ffffff',
        hover: 'rgba(85, 108, 214, 0.08)',
      },
      input: {
        textColor: '#ffffff',
        border: '#ffffff',
        autocompleteBackground: 'rgba(59, 63, 73, 0.95)',
        borderHover: '#84a5f9',
        label: '#ffffff',
        icon: '#ffffff',
        error: '#9c1f42',
      },
    },
  },
  palette: {
    mode: EMuiThemeMode.light,
  },
};
