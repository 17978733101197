import styled from 'styled-components';

export const ServiceItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.servicesScreen.versionScreen.serviceItem.border};
  box-shadow: 0 2px 6px
    ${({ theme }) => theme.colors.servicesScreen.versionScreen.serviceItem.boxShadow};
  user-select: none;
`;
