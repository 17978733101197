import { Page404NotFoundWrapper, Text404 } from './elements';
import { ErrorText } from './elements/ErrorText';
import { Text404Title } from './elements/Text404Title';

export const Page404NotFound = () => {
  return (
    <Page404NotFoundWrapper>
      <Text404>404</Text404>
      <Text404Title>Страница не найдена</Text404Title>
      <ErrorText>Страницы c таким URL не существует</ErrorText>
    </Page404NotFoundWrapper>
  );
};
