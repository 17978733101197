import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return {
    width: '100%',
    '& input.MuiOutlinedInput-input': {
      color: styledTheme.colors.loginPage.input.textColor,
    },
    '& label': {
      color: styledTheme.colors.loginPage.input.border,
    },
    '& label.Mui-error': {
      color: styledTheme.colors.loginPage.input.error,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: styledTheme.colors.loginPage.input.border,
    },
    '& .MuiInputBase-formControl.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: styledTheme.colors.loginPage.input.error,
    },
    '&:hover .MuiInputBase-formControl:not(.Mui-disabled):not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline':
      {
        borderColor: styledTheme.colors.loginPage.input.borderHover,
      },
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
      {
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': styledTheme.colors.loginPage.input.textColor,
        transition: 'background-color 5000s ease-in-out 0s',
        boxShadow: `inset 0 0 200px 200px ${styledTheme.colors.loginPage.input.autocompleteBackground}`,
      },
  };
});
