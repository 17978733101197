import { useCallback, useEffect, useState } from 'react';
import { Location, useBlocker, useNavigate } from 'react-router-dom';

export const useUnsavedChangesGuard = (hasUnsavedChanges: boolean) => {
  const navigate = useNavigate();
  const [nextLocation, setNextLocation] = useState(null);
  const [blockerEnabled, setBlockerEnabled] = useState(hasUnsavedChanges);

  useEffect(() => {
    setBlockerEnabled(hasUnsavedChanges); // Sync blocker state with hasUnsavedChanges
  }, [hasUnsavedChanges]);

  const handleNavigationConfirm = useCallback(() => {
    if (nextLocation) {
      setBlockerEnabled(false); // Disable the blocker
      setTimeout(() => {
        navigate(nextLocation.pathname); // Navigate after ensuring blocker is unset
      }, 0);

      setNextLocation(null); // Clear the stored location
    }
  }, [nextLocation, navigate]);

  const handleNavigationCancel = useCallback(() => {
    setNextLocation(null); // Reset the stored location
  }, []);

  const blocker = useCallback(
    ({ nextLocation }: { nextLocation: Location }) => {
      if (blockerEnabled && hasUnsavedChanges) {
        setNextLocation(nextLocation); // Store the location to navigate later
        return true; // Block navigation
      }
      return false; // Allow navigation
    },
    [blockerEnabled, hasUnsavedChanges, nextLocation]
  );

  useBlocker(blocker);

  return {
    handleNavigationConfirm,
    handleNavigationCancel,
    isBlocked: Boolean(nextLocation),
  };
};
