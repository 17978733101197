import { FC, useMemo } from 'react';
import { GridColDef, GridRowEntry, GridRowParams } from '@mui/x-data-grid';
import { Box, FormHelperText, SxProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { FormikErrors } from 'formik';

import { removePromotionCollectionPromotionById } from '@/features/promotions/redux/promotions.slice';
import { IPromotionItem } from '@/features/promotions/types';
import { TRootState, useAppDispatch } from '@/store';
import { ActionButtonWrapper, Symbol } from './elements';
import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';
import { StyledDataGrid } from '@/shared/components/StyledDataGrid';

interface IPromotionTableProps {
  sx?: SxProps;
  promotionItems: IPromotionItem[];
  isPromotionCollectionArchived: boolean;
  error?: boolean;
  helperText?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}

export const PromotionTable: FC<IPromotionTableProps> = (props) => {
  const { promotionItems = [], isPromotionCollectionArchived } = props || {};
  const dispatch = useAppDispatch();
  const selectedPromotionIds = useSelector(
    (state: TRootState) => state.promotions.selectedPromotionIds
  );
  const muiThemeMode = useSelector((state: TRootState) => state.appGeneral.muiThemeMode);

  const removePromotionFromCollection = (row: GridRowEntry) => {
    dispatch(removePromotionCollectionPromotionById(row.id));
  };

  const rows = useMemo(() => {
    const promotionItemsForTable = promotionItems?.map((promotionItem) => {
      return {
        id: promotionItem.id,
        name: promotionItem.name,
        type: promotionItem.type?.name,
        status: promotionItem.status?.name,
      };
    });

    return promotionItemsForTable || [];
  }, [promotionItems]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Название', width: 300 },
    { field: 'type', headerName: 'Тип акции', width: 170 },
    {
      field: 'status',
      headerName: 'Статус',
      width: 170,
    },
    {
      field: 'action',
      headerName: 'Действия',
      sortable: false,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        !isPromotionCollectionArchived ? (
          <ActionButtonWrapper>
            <Symbol onClick={() => removePromotionFromCollection(row)}>Delete</Symbol>
          </ActionButtonWrapper>
        ) : null,
    },
  ];

  const styledTheme = muiThemeMode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return (
    <Box sx={{ ...props.sx, width: '100%' }}>
      <StyledDataGrid
        sx={{
          height: '400px',
          border: props.error ? `2px solid ${styledTheme.colors.authLayoutError}` : null,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableRowSelectionOnClick
        rowSelectionModel={selectedPromotionIds}
      />
      {props.error && (
        <FormHelperText sx={{ mt: '7px' }} error={props.error}>
          {props.helperText as string}
        </FormHelperText>
      )}
    </Box>
  );
};
