import React, { SyntheticEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash.isempty';

import { TRootState, useAppDispatch } from '@/store';
import {
  NotificationTaskContainer,
  NotificationTaskHedaing,
  NotificationTaskControlsContainer,
  NotificationTaskTextarea,
  NotificationTextField,
} from './elements';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ColorPickerWithTextField } from '@/shared/components/ColorPickerWithTextField';
import { MainButton } from '@/shared/components/MainButton';

import { ERequestStatus } from '@/shared/lib/types';
import { ROUTES, actionButtonOptionTexts, actionButtonOptionValues } from '@/shared/lib/const';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import { CustomSelect } from '@/shared/components/CustomSelect';
import {
  notificationSendingTimeRadioGroupOptionTexts,
  notificationSendingTimeRadioGroupOptionValues,
} from '@/features/notificationTasks/const';
import { CustomDateTimePicker } from '@/shared/components/CustomDateTimePicker';
import CustomRadioGroup from '@/shared/components/CustomRadioGroup';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import {
  ENotificationActionType,
  ENotificationProcessType,
  ENotificationTaskStatus,
} from '@/features/notificationTasks/types';
import {
  setNewNotificationTaskСontactsDataUrl,
  setNewNotificationTaskButtonColor,
  setNewNotificationTaskButtonTitleValue,
  setNewNotificationTaskButtonType,
  setNewNotificationTaskButtonValue,
  setNewNotificationTaskDescriptionValue,
  setNewNotificationTaskDetailsDescriptionValue,
  setNewNotificationTaskDetailsTitleValue,
  setNewNotificationTaskSendPushValue,
  setNewNotificationTaskTitleValue,
  setNotificationTaskСontactsDataUrl,
  setNotificationTaskButtonColor,
  setNotificationTaskButtonTitleValue,
  setNotificationTaskButtonType,
  setNotificationTaskButtonValue,
  setNotificationTaskDescriptionValue,
  setNotificationTaskDetailsDescriptionValue,
  setNotificationTaskDetailsTitleValue,
  setNotificationTaskSendPushValue,
  setNotificationTaskTitleValue,
  setNewNotificationTaskNotificationProcessType,
  setNotificationTaskNotificationProcessType,
  setNewNotificationTaskNotificationProcessScheduleDatetime,
  setNotificationTaskNotificationProcessScheduleDatetime,
  deleteNewNotification,
} from '@/features/notificationTasks/redux/notificationTasks.slice';
import { EUploadFileType } from '@/features/stories/types';
import {
  isNotificationTaskTouched,
  isValidNotificationTask,
} from '@/features/notificationTasks/utils';
import {
  fetchNotificationTaskList,
  saveNotificationTask,
} from '@/features/notificationTasks/redux/notificationTasks.actions';
import Yup from '@/shared/validations';
import { showSuccessNotification } from '@/shared/utils';

const validationSchema = Yup.object().shape({
  title: Yup.string().notificationTitleAndDetailsTitleTextValueValidation(),
  description: Yup.string().notificationDescriptionAndDetailsDescriptionValueValidation(),
  detailsTitle: Yup.string().notificationTitleAndDetailsTitleTextValueValidation(),
  detailsDescription: Yup.string().notificationDescriptionAndDetailsDescriptionValueValidation(),
  contactsDataUrl: Yup.string().externalLinkInputValidation(),
  buttonTitle: Yup.string().itemButtonTextValueValidation(),
  buttonColor: Yup.string().colorInputValidation(),
  buttonType: Yup.string().itemActionTypeValidation(),
  buttonValue: Yup.string().externalLinkInputValidation(),
  notificationProcessScheduleDatetime: Yup.string().notificationProcessScheduleDatetimeValidation(),
});

export const NotificationTaskContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tasks = useSelector((state: TRootState) => state.notificationTasks.tasks);
  const task = useSelector((state: TRootState) => state.notificationTasks.task);
  const taskInitial = useSelector((state: TRootState) => state.notificationTasks.taskInitial);
  const newTask = useSelector((state: TRootState) => state.notificationTasks.newTask);
  const fetchNotificationTaskStatus = useSelector(
    (state: TRootState) => state.notificationTasks.fetchNotificationTaskStatus
  );
  const saveNotificationTaskStatus = useSelector(
    (state: TRootState) => state.notificationTasks.saveNotificationTaskStatus
  );
  const { service, module, notification: taskId } = useParams();
  const [
    isNotificationProcessScheduleDatetimeDisabled,
    setIsNotificationProcessScheduleDatetimeDisabled,
  ] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      detailsTitle: '',
      detailsDescription: '',
      contactsDataUrl: '',
      sendPush: true,
      buttonTitle: '',
      buttonColor: '',
      buttonType: null,
      buttonValue: '',
      notificationProcessType: ENotificationProcessType.now,
      notificationProcessScheduleDatetime: '',
    },
    validationSchema,
    onSubmit: (values, actions) => {},
  });

  useLayoutEffect(() => {
    formik.resetForm();
  }, [taskId]);

  useEffect(() => {
    let taskData;
    if (taskId === 'new-notification') {
      taskData = newTask;
    } else if (task) {
      taskData = task;
    }

    if (taskData) {
      formik.setFieldValue('title', taskData.title);
      formik.setFieldValue('description', taskData.description);
      formik.setFieldValue('detailsTitle', taskData.details_title);
      formik.setFieldValue('detailsDescription', taskData.details_description);
      formik.setFieldValue('contactsDataUrl', taskData.contacts_data_url);
      formik.setFieldValue('sendPush', taskData.send_push);
      formik.setFieldValue('buttonTitle', taskData.button?.title);
      formik.setFieldValue('buttonColor', taskData.button?.color);
      formik.setFieldValue('buttonType', taskData.button?.value?.type);
      formik.setFieldValue('buttonValue', taskData.button?.value?.value);
      formik.setFieldValue('notificationProcessType', taskData.notification_process?.type);
      formik.setFieldValue(
        'notificationProcessScheduleDatetime',
        taskData.notification_process?.schedule_datetime
      );
    }
  }, [task, newTask, taskId]);

  const taskListItemStatus = useMemo(() => {
    const taskListItem = tasks?.find((taskListItem) => {
      return taskListItem.id === Number(taskId);
    });
    return taskListItem?.status;
  }, [tasks, taskId]);

  const isSaveBtnDisabled = useMemo(() => {
    return (
      fetchNotificationTaskStatus === ERequestStatus.pending ||
      saveNotificationTaskStatus === ERequestStatus.pending
    );
  }, [fetchNotificationTaskStatus, saveNotificationTaskStatus]);

  const isFormControlsDisabled = useMemo(() => {
    return (
      taskId !== 'new-notification' &&
      (taskListItemStatus !== ENotificationTaskStatus.draft ||
        fetchNotificationTaskStatus === ERequestStatus.pending)
    );
  }, [taskId, fetchNotificationTaskStatus, taskListItemStatus]);

  const handleSaveBtnClick = (actionType: ENotificationActionType) => {
    formik.setTouched({
      title: true,
      description: true,
      detailsTitle: true,
      detailsDescription: true,
      contactsDataUrl: true,
      buttonTitle: true,
      buttonType: true,
      buttonColor: true,
      buttonValue: true,
      notificationProcessScheduleDatetime: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        if (taskId === 'new-notification') {
          dispatch(
            saveNotificationTask({
              actionType,
              isNewNotification: true,
            })
          )
            .unwrap()
            .then((data) => {
              dispatch(deleteNewNotification());
              showSuccessNotification({ message: 'Уведомление было успешно сохранено!' });
              navigate(
                ROUTES.notificationTask
                  .replace(':service', service)
                  .replace(':module', module)
                  .replace(':notification', data.task_id.toString())
              );
            });
        } else {
          dispatch(
            saveNotificationTask({
              taskId,
              actionType,
              isNewNotification: false,
            })
          )
            .unwrap()
            .then(() => {
              dispatch(fetchNotificationTaskList());
              showSuccessNotification({
                message: 'Уведомление было успешно сохранено!',
              });
            });
        }
      }
    });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskTitleValue(event.target.value));
    } else {
      dispatch(setNotificationTaskTitleValue(event.target.value));
    }
    formik.setFieldValue('title', event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskDescriptionValue(event.target.value));
    } else {
      dispatch(setNotificationTaskDescriptionValue(event.target.value));
    }
    formik.setFieldValue('description', event.target.value);
  };

  const handleSendPushCheckboxChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskSendPushValue(checked));
    } else {
      dispatch(setNotificationTaskSendPushValue(checked));
    }
    formik.setFieldValue('sendPush', checked);
  };

  const handleDetailsTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskDetailsTitleValue(event.target.value));
    } else {
      dispatch(setNotificationTaskDetailsTitleValue(event.target.value));
    }
    formik.setFieldValue('detailsTitle', event.target.value);
  };

  const handleDetailsDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskDetailsDescriptionValue(event.target.value));
    } else {
      dispatch(setNotificationTaskDetailsDescriptionValue(event.target.value));
    }
    formik.setFieldValue('detailsDescription', event.target.value);
  };

  const handleButtonTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskButtonTitleValue(event.target.value));
    } else {
      dispatch(setNotificationTaskButtonTitleValue(event.target.value));
    }
    formik.setFieldValue('buttonTitle', event.target.value);
  };

  const handleButtonColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskButtonColor(event.target.value));
    } else {
      dispatch(setNotificationTaskButtonColor(event.target.value));
    }
    formik.setFieldValue('buttonColor', event.target.value);
  };
  const handleButtonColorFromColorPickerChange = (color: string) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskButtonColor(color));
    } else {
      dispatch(setNotificationTaskButtonColor(color));
    }
    formik.setFieldValue('buttonColor', color);
  };

  const handleButtonTypeChange = (value: string) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskButtonType(value));
    } else {
      dispatch(setNotificationTaskButtonType(value));
    }
    formik.setFieldValue('buttonType', value);
  };

  const handleButtonValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskButtonValue(event.target.value));
    } else {
      dispatch(setNotificationTaskButtonValue(event.target.value));
    }
    formik.setFieldValue('buttonValue', event.target.value);
  };

  const handleСontactsDataUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskСontactsDataUrl(event.target.value));
    } else {
      dispatch(setNotificationTaskСontactsDataUrl(event.target.value));
    }
    formik.setFieldValue('contactsDataUrl', event.target.value);
  };
  const handleСontactsDataUrlUpload = (url: string) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskСontactsDataUrl(url));
    } else {
      dispatch(setNotificationTaskСontactsDataUrl(url));
    }
    formik.setFieldValue('contactsDataUrl', url);
  };

  const handleNotificationProcessTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskNotificationProcessType(event.target.value));
    } else {
      dispatch(setNotificationTaskNotificationProcessType(event.target.value));
    }
    formik.setFieldValue('notificationProcessType', event.target.value);

    if (event.target.value === ENotificationProcessType.now) {
      setIsNotificationProcessScheduleDatetimeDisabled(true);
      if (taskId === 'new-notification') {
        dispatch(setNewNotificationTaskNotificationProcessScheduleDatetime(''));
      } else {
        dispatch(setNotificationTaskNotificationProcessScheduleDatetime(''));
      }
    } else {
      setIsNotificationProcessScheduleDatetimeDisabled(false);
    }
  };

  const handleNotificationProcessScheduleDatetimeChange = (date: string) => {
    if (taskId === 'new-notification') {
      dispatch(setNewNotificationTaskNotificationProcessScheduleDatetime(date));
    } else {
      dispatch(setNotificationTaskNotificationProcessScheduleDatetime(date));
    }
    formik.setFieldValue('notificationProcessScheduleDatetime', date);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <NotificationTaskContainer>
          <NotificationTaskHedaing>Создать рассылку уведомлений</NotificationTaskHedaing>
          <NotificationTaskControlsContainer>
            <NotificationTextField
              label="Идентификатор"
              type="text"
              size="small"
              value={taskId}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mb: '10px' }}
              disabled={true}
            />
            <TextFieldGroup
              sx={{ mb: '16px' }}
              title="Данные уведомления"
              disabled={isFormControlsDisabled}
            >
              <NotificationTextField
                label="Заголовок"
                type="text"
                size="small"
                value={formik.values.title}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: '10px' }}
                onChange={handleTitleChange}
                disabled={isFormControlsDisabled}
                error={formik.touched.title && !!formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
              />
              <NotificationTaskTextarea
                label="Текст"
                rows={3}
                ariaLabel="Текст уведомления"
                placeholder="Текст"
                value={formik.values.description}
                onChange={handleDescriptionChange}
                disabled={isFormControlsDisabled}
                error={formik.touched.description && !!formik.errors.description}
                helperText={formik.touched.description && formik.errors.description}
              />
              <FormControlLabel
                control={<Checkbox />}
                checked={formik.values.sendPush}
                label="Отправить с пушем"
                sx={{ mb: '10px' }}
                onChange={handleSendPushCheckboxChange}
                disabled={isFormControlsDisabled}
              />
              <NotificationTextField
                label="Заголовок деталей"
                type="text"
                size="small"
                value={formik.values.detailsTitle}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: '10px' }}
                onChange={handleDetailsTitleChange}
                disabled={isFormControlsDisabled}
                error={formik.touched.detailsTitle && !!formik.errors.detailsTitle}
                helperText={formik.touched.detailsTitle && formik.errors.detailsTitle}
              />
              <NotificationTaskTextarea
                label="Текст деталей"
                rows={3}
                ariaLabel="Текст деталей"
                value={formik.values.detailsDescription}
                placeholder="Текст деталей"
                sx={{ mb: '10px' }}
                onChange={handleDetailsDescriptionChange}
                disabled={isFormControlsDisabled}
                error={formik.touched.detailsDescription && !!formik.errors.detailsDescription}
                helperText={formik.touched.detailsDescription && formik.errors.detailsDescription}
              />
              <TextFieldGroup title="Кнопка" disabled={isFormControlsDisabled}>
                <NotificationTextField
                  label="Текст"
                  type="text"
                  size="small"
                  value={formik.values.buttonTitle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mb: '16px' }}
                  onChange={handleButtonTitleChange}
                  disabled={isFormControlsDisabled}
                  error={formik.touched.buttonTitle && !!formik.errors.buttonTitle}
                  helperText={formik.touched.buttonTitle && formik.errors.buttonTitle}
                />
                <ColorPickerWithTextField
                  label="Цвет"
                  type="text"
                  size="small"
                  value={formik.values.buttonColor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onInput={handleButtonColorChange}
                  handleColorChange={handleButtonColorFromColorPickerChange}
                  disabled={isFormControlsDisabled}
                  error={formik.touched.buttonColor && !!formik.errors.buttonColor}
                  helperText={formik.touched.buttonColor && formik.errors.buttonColor}
                />
                <TextFieldGroup title="Действие" disabled={isFormControlsDisabled}>
                  <CustomSelect
                    label="Тип"
                    value={formik.values.buttonType}
                    optionValues={actionButtonOptionValues}
                    optionTexts={actionButtonOptionTexts}
                    sx={{
                      mb: formik.touched.buttonType && formik.errors.buttonType ? '10px' : '16px',
                    }}
                    onChange={handleButtonTypeChange}
                    disabled={isFormControlsDisabled}
                    error={formik.touched.buttonType && !!formik.errors.buttonType}
                    helperText={formik.touched.buttonType && formik.errors.buttonType}
                  />
                  <NotificationTextField
                    label="Ссылка"
                    type="text"
                    size="small"
                    value={formik.values.buttonValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleButtonValueChange}
                    disabled={isFormControlsDisabled}
                    error={formik.touched.buttonValue && !!formik.errors.buttonValue}
                    helperText={formik.touched.buttonValue && formik.errors.buttonValue}
                  />
                </TextFieldGroup>
              </TextFieldGroup>
            </TextFieldGroup>
            <UploadFileWithTextField
              label="Ссылка на список контактов"
              type="text"
              size="small"
              fileType={EUploadFileType.file}
              value={formik.values.contactsDataUrl}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleСontactsDataUrlChange}
              handleFileUpload={handleСontactsDataUrlUpload}
              sx={{
                mb: formik.touched.contactsDataUrl && formik.errors.contactsDataUrl ? '3px' : '8px',
              }}
              disabled={isFormControlsDisabled}
              error={formik.touched.contactsDataUrl && !!formik.errors.contactsDataUrl}
              helperText={formik.touched.contactsDataUrl && formik.errors.contactsDataUrl}
            />
            <TextFieldGroup
              title="Расписание"
              sx={{ pb: '3px', mb: '16px' }}
              disabled={isFormControlsDisabled}
            >
              <CustomRadioGroup
                label="Время отправки"
                value={formik.values.notificationProcessType}
                radioGroupValues={notificationSendingTimeRadioGroupOptionValues}
                radioGroupTexts={notificationSendingTimeRadioGroupOptionTexts}
                onChange={handleNotificationProcessTypeChange}
                disabled={isFormControlsDisabled}
              />
              <CustomDateTimePicker
                sx={{ mb: 0 }}
                label="Дата и время"
                value={formik.values.notificationProcessScheduleDatetime}
                onChange={handleNotificationProcessScheduleDatetimeChange}
                disabled={isNotificationProcessScheduleDatetimeDisabled || isFormControlsDisabled}
                error={
                  formik.touched.notificationProcessScheduleDatetime &&
                  !!formik.errors.notificationProcessScheduleDatetime
                }
                helperText={
                  formik.touched.notificationProcessScheduleDatetime &&
                  formik.errors.notificationProcessScheduleDatetime
                }
              />
            </TextFieldGroup>
            <SecondaryButton
              sx={{ mb: 2, width: '240px' }}
              disabled={isSaveBtnDisabled}
              onClick={() => handleSaveBtnClick(ENotificationActionType.draft)}
            >
              Сохранить как черновик
            </SecondaryButton>
            <MainButton
              sx={{ mb: 2, width: '150px' }}
              disabled={isSaveBtnDisabled}
              onClick={() => handleSaveBtnClick(ENotificationActionType.commit)}
            >
              Отправить
            </MainButton>
          </NotificationTaskControlsContainer>
        </NotificationTaskContainer>
      </Form>
    </FormikProvider>
  );
};
