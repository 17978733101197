import { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import gsap from 'gsap';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

import {
  EmptySpace,
  LogoLink,
  SidebarWrapper,
  ThemeSwitch,
  ThemeSwitchContainer,
  TopSection,
} from './elements';
import { SidebarContent } from './elements';
import { MenuButton } from '@/features/sidebarMenus/MainMenu/components/MenuButton';
import { ROUTES } from '@/shared/lib/const';
import { StyledLink } from './elements';
import { CollapseMenuButton } from './components/CollapseMenuButton';
import { SmartPlatformLogo } from './components/SmartPlatformLogo';
import { MuiMenuButton } from './components/MuiMenuButton';
import { TRootState, useAppDispatch } from '@/store';
import { EMuiThemeMode } from '@/app/types';
import { setMuiThemeMode } from '@/app/redux/appGeneral.slice';
import { CollapsedThemeSwitchBtn } from './components/CollapsedThemeSwitchBtn';
import { localStorageForFeatures } from '@/packages/localStorageForFeatures';

export const MainMenu = () => {
  const dispatch = useAppDispatch();
  const muiThemeMode = useSelector((state: TRootState) => state.appGeneral.muiThemeMode);
  const sidebarWrapperRef = useRef();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCollapsedInitial, setIsCollapsedInitial] = useState(null);
  const [muiThemeSwitchChecked, setMuiThemeSwitchChecked] = useState(false);

  useLayoutEffect(() => {
    const isCollapsedLocalStorage = localStorageForFeatures.getItem({
      feature: 'appGeneral',
      key: 'isMainMenyCollapsed',
    });

    if (isCollapsedLocalStorage !== null) {
      setIsCollapsedInitial(isCollapsedLocalStorage);
      setIsCollapsed(isCollapsedLocalStorage);
    }
  }, []);

  useLayoutEffect(() => {
    const isChecked = muiThemeMode === EMuiThemeMode.dark;
    setMuiThemeSwitchChecked(isChecked);
  }, [muiThemeMode]);

  const handleMuiThemeSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMuiThemeSwitchChecked(event.target.checked);
    const muiThemeMode = event.target.checked ? EMuiThemeMode.dark : EMuiThemeMode.light;
    dispatch(setMuiThemeMode(muiThemeMode));
    localStorageForFeatures.setItem({
      feature: 'appGeneral',
      data: { key: 'themeMode', value: muiThemeMode },
    });
  };

  const handleCollapsedThemeSwitchBtnChange = (muiThemeMode: EMuiThemeMode) => {
    const isChecked = muiThemeMode === EMuiThemeMode.dark ? true : false;
    setMuiThemeSwitchChecked(isChecked);
    dispatch(setMuiThemeMode(muiThemeMode));
    localStorageForFeatures.setItem({
      feature: 'appGeneral',
      data: { key: 'themeMode', value: muiThemeMode },
    });
  };

  const onСollapseBtnClick = () => {
    if (isCollapsed) {
      gsap.to(sidebarWrapperRef.current, { width: '195px' });
      setIsCollapsed(false);
      localStorageForFeatures.setItem({
        feature: 'appGeneral',
        data: { key: 'isMainMenyCollapsed', value: false },
      });
    } else {
      gsap.to(sidebarWrapperRef.current, { width: '46px' });
      setIsCollapsed(true);
      localStorageForFeatures.setItem({
        feature: 'appGeneral',
        data: { key: 'isMainMenyCollapsed', value: true },
      });
    }
  };

  return (
    <SidebarWrapper $withGradient ref={sidebarWrapperRef} $isCollapsedInitial={isCollapsedInitial}>
      <Scrollbars>
        <SidebarContent>
          <LogoLink to="/">
            <SmartPlatformLogo />
          </LogoLink>
          <TopSection>
            <MuiMenuButton Icon={AppsOutlinedIcon} text="Все сервисы" />
            <MuiMenuButton Icon={SearchOutlinedIcon} text="Поиск" />
          </TopSection>
          <div>
            <MuiMenuButton Icon={EmailOutlinedIcon} text="Написать нам" />
            <StyledLink to={ROUTES.settings}>
              <MuiMenuButton Icon={SettingsOutlinedIcon} text="Настройки" />
            </StyledLink>
            <MuiMenuButton Icon={StarOutlineOutlinedIcon} text="Избранное" />
            <MuiMenuButton Icon={NotificationsOutlinedIcon} text="Уведомления" />
            <MuiMenuButton Icon={HelpOutlineOutlinedIcon} text="Помощь" />
            <MuiMenuButton Icon={PermIdentityOutlinedIcon} text="Учетная запись" />
          </div>
          <EmptySpace />
          <ThemeSwitchContainer>
            {isCollapsed ? (
              <CollapsedThemeSwitchBtn
                onChange={handleCollapsedThemeSwitchBtnChange}
                muiThemeMode={muiThemeSwitchChecked ? EMuiThemeMode.dark : EMuiThemeMode.light}
                sx={{ position: 'relative', top: '-1px', marginLeft: '6px' }}
              />
            ) : (
              <ThemeSwitch checked={muiThemeSwitchChecked} onChange={handleMuiThemeSwitchChange} />
            )}
          </ThemeSwitchContainer>
          <CollapseMenuButton
            isCollapsed={isCollapsed}
            text="Свернуть"
            onClick={onСollapseBtnClick}
          />
        </SidebarContent>
      </Scrollbars>
    </SidebarWrapper>
  );
};
