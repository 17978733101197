import { combineReducers } from '@reduxjs/toolkit';

import services from '@/features/sidebarMenus/redux/services.slice';
import modules from '@/features/modules/redux/modules.slice';
import authentication from '@/features/auth/redux/authentication.slice';
import stories from '@/features/stories/redux/stories.slice';
import promotions from '@/features/promotions/redux/promotions.slice';
import clientSettings from '@/features/sidebarMenus/MainMenu/ClientSettings/redux/clientSettings.slice';
import bannerScenarios from '@/features/banners/redux/scenarios.slice';
import notificationTasks from '@/features/notificationTasks/redux/notificationTasks.slice';
import servicesScreen from '@/features/servicesScreen/redux/servicesScreen.slice';
import dispatchersWorkplace from '@/features/dispatchers-workplace/redux';
import appGeneral from '@/app/redux/appGeneral.slice';

const reducers = combineReducers({
  authentication,
  services,
  modules,
  stories,
  promotions,
  clientSettings,
  bannerScenarios,
  notificationTasks,
  servicesScreen,
  dispatchersWorkplace,
  appGeneral,
});

export default reducers;
