import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Footer, Header, StoriesContainer } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import {
  setMiddleMenuOptions,
  setMiddleMenuActiveBtn,
} from '@/features/sidebarMenus/redux/services.slice';
import { fetchBannerList, fetchBannerScenarios, saveBannerList } from '../redux/scenarios.actions';
import { SortableList } from '@/shared/components/SortableList';
import { Card } from './components/Card';
import { AddBanner } from './components/AddBanner';
import { MainButton } from '@/shared/components/MainButton';
import { IBannerListItem } from '../types';
import { setBannerList } from '../redux/scenarios.slice';
import { ERequestStatus } from '@/shared/lib/types';
import { isBannerListTouched } from '../utils';
import { deleteNewBanner } from '@/features/banners/redux/scenarios.slice';
import { showSuccessNotification } from '@/shared/utils';

const Scenario = () => {
  const { scenario: scenarioId } = useParams();
  const bannerScenarios = useSelector((state: TRootState) => state.bannerScenarios.bannerScenarios);
  const bannerListInitial = useSelector(
    (state: TRootState) => state.bannerScenarios.bannerListInitial
  );
  const bannerList = useSelector((state: TRootState) => state.bannerScenarios.bannerList);
  const fetchBannerListStatus = useSelector(
    (state: TRootState) => state.bannerScenarios.fetchBannerListStatus
  );
  const saveBannerListStatus = useSelector(
    (state: TRootState) => state.bannerScenarios.saveBannerListStatus
  );
  const dispatch = useAppDispatch();

  const isSaveBtnDisabled = useMemo(() => {
    return (
      !isBannerListTouched({ bannerListInitial, bannerList }) ||
      fetchBannerListStatus === ERequestStatus.pending ||
      saveBannerListStatus === ERequestStatus.pending
    );
  }, [bannerListInitial, bannerList, fetchBannerListStatus, saveBannerListStatus]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(fetchBannerList(scenarioId));
    }
  }, [dispatch, scenarioId]);

  useEffect(() => {
    if (bannerScenarios.length === 0) {
      dispatch(fetchBannerScenarios());
    }
  }, [dispatch, bannerScenarios]);

  useEffect(() => {
    if (bannerScenarios) {
      dispatch(setMiddleMenuOptions(bannerScenarios));
    }
  }, [dispatch, bannerScenarios]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(setMiddleMenuActiveBtn(scenarioId));
    }
  }, [dispatch, scenarioId]);

  useEffect(() => {
    dispatch(deleteNewBanner());
  }, [dispatch]);

  const handleSaveBtnClick = () => {
    dispatch(
      saveBannerList({
        scenarioId,
        bannerListData: bannerList,
      })
    )
      .unwrap()
      .then(() => {
        showSuccessNotification({ message: 'Баннеры были успешно сохранены!' });
        dispatch(fetchBannerList(scenarioId));
      });
  };

  const updateBanners = (nextBannerList: IBannerListItem[]) => {
    if (nextBannerList.length !== 0) {
      dispatch(setBannerList(nextBannerList));
    }
  };

  return (
    <StoriesContainer>
      <Header>
        <MainButton onClick={handleSaveBtnClick} disabled={isSaveBtnDisabled}>
          Сохранить
        </MainButton>
      </Header>
      <SortableList
        updateCards={updateBanners}
        cards={bannerList}
        cardComponent={Card}
      ></SortableList>
      <Footer>
        <AddBanner />
      </Footer>
    </StoriesContainer>
  );
};

export default Scenario;
