import { MenuItem } from '@mui/material';
import styled from 'styled-components';

export const StyledMenuItem = styled(MenuItem)<{ $hasSubtitle: boolean; $index: number }>`
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  height: ${({ $hasSubtitle, $index }) => {
    if ($hasSubtitle) {
      if ($index === 0) {
        return '36px';
      } else {
        return '58px';
      }
    } else {
      return '36px';
    }
  }};
`;
