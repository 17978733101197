import { ReactNode, useState, FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { SxProps, TextField } from '@mui/material';

import { TRootState, useAppDispatch } from '@/store';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { SearchInput } from '@/shared/components/SearchInput';
import { PromotionCollectionListItemComponent } from './components/PromotionCollectionListItemComponent';
import {
  setPromotionCollectionActivePage,
  setPromotionCollectionListItems,
  setPromotionCollectionsTabValue,
  updatePromotionCollectionSearch,
} from '../../redux/promotions.slice';
import { SortableList } from '@/shared/components/SortableList';
import {
  EPromotionCollectionStatusKey,
  EPromotionCollectionTabPages,
  IPromotionCollectionListItem,
} from '../../types';
import { getNewPromotionCollectionList, isListItemsOrderChanged } from '../../utils';
import { ConfirmPromotionCollectionOrderChangeModal } from './components/ConfirmPromotionCollectionOrderChangeModal';
import {
  fetchPromotionCollectionListItems,
  savePromotionCollectionListItemsOrder,
} from '../../redux/promotions.actions';
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const tabTitles = ['Черновики', 'Архивы', 'Опубликованы'];

export enum EPromotionCollectionTabs {
  drafts,
  archives,
  published,
}

interface IPromotionCollectionTabProps {
  sx?: SxProps;
}

export const PromotionCollectionList: FC<IPromotionCollectionTabProps> = ({ sx }) => {
  const [isConfirmOrderChangeModalOpened, setIsConfirmOrderChangeModalOpened] = useState(false);
  const [nextPromotionCollectionList, setNextPromotionCollectionList] = useState(null);
  const searchValue = useSelector(
    (state: TRootState) => state.promotions.promotionCollectionSearch
  );
  const promotionCollectionsStore = useSelector(
    (state: TRootState) => state.promotions.promotionCollections
  );
  const tabValue = useSelector(
    (state: TRootState) => state.promotions.promotionCollectionsTabValue
  );

  const dispatch = useAppDispatch();

  const promotionCollections = useMemo(() => {
    if (tabValue === EPromotionCollectionTabs.drafts) {
      return promotionCollectionsStore.filter((promotionCollectionItem) => {
        return (
          promotionCollectionItem.status.key === EPromotionCollectionStatusKey.draft &&
          promotionCollectionItem.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    } else if (tabValue === EPromotionCollectionTabs.archives) {
      return promotionCollectionsStore.filter((promotionCollectionItem) => {
        return (
          promotionCollectionItem.status.key === EPromotionCollectionStatusKey.archived &&
          promotionCollectionItem.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    } else if (tabValue === EPromotionCollectionTabs.published) {
      return promotionCollectionsStore.filter((promotionCollectionItem) => {
        return (
          (promotionCollectionItem.status.key === EPromotionCollectionStatusKey.published ||
            promotionCollectionItem.status.key === EPromotionCollectionStatusKey.ready) &&
          promotionCollectionItem.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }
    return promotionCollectionsStore;
  }, [searchValue, tabValue, promotionCollectionsStore]);

  useEffect(() => {
    dispatch(fetchPromotionCollectionListItems());
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setPromotionCollectionsTabValue(newValue));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePromotionCollectionSearch(event.target.value));
  };

  const updatePromotionCollections = (
    newPromotionCollectionListFiltered: IPromotionCollectionListItem[]
  ) => {
    if (newPromotionCollectionListFiltered.length !== 0) {
      const isOrderChanged = isListItemsOrderChanged({
        originalItemList: promotionCollections,
        nextItemList: newPromotionCollectionListFiltered,
      });
      if (isOrderChanged) {
        const nextPromotionCollectionListLocal = getNewPromotionCollectionList({
          originalPromotionCollections: promotionCollectionsStore,
          newPromotionCollectionListFiltered,
        });
        if (tabValue === EPromotionCollectionTabs.published) {
          setIsConfirmOrderChangeModalOpened(true);
          setNextPromotionCollectionList(nextPromotionCollectionListLocal);
        } else {
          dispatch(setPromotionCollectionListItems(nextPromotionCollectionListLocal));
          dispatch(savePromotionCollectionListItemsOrder());
        }
      }
    }
  };

  const handleConfirmPromotionCollectionOrderChange = () => {
    dispatch(setPromotionCollectionListItems(nextPromotionCollectionList));
    dispatch(savePromotionCollectionListItemsOrder());
    setIsConfirmOrderChangeModalOpened(false);
  };

  const handleCancelPromotionCollectionOrderChange = () => {
    dispatch(setPromotionCollectionListItems([...promotionCollectionsStore]));
    setIsConfirmOrderChangeModalOpened(false);
  };

  const handleCreateNewPromotionBtnClick = () => {
    dispatch(setPromotionCollectionActivePage(EPromotionCollectionTabPages.newPromotionCollection));
  };

  return (
    <>
      <Box sx={{ width: '100%', height: '100%', minWidth: '540px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 3,
            paddingRight: 3,
            paddingLeft: 3,
            paddingBottom: 1,
          }}
        >
          <SearchInput
            label="Название"
            sx={{ maxWidth: '260px', mr: 2 }}
            value={searchValue}
            onChange={handleSearchChange}
          />
          <SecondaryButton
            sx={{ width: '185px' }}
            size="large"
            startIcon={<AddRoundedIcon style={{ fontSize: 30 }} />}
            onClick={handleCreateNewPromotionBtnClick}
          >
            Новая подборка
          </SecondaryButton>
        </Box>
        <Box>
          <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
            {tabTitles.map((tabTitle, index) => {
              return <Tab key={index} label={tabTitle} {...a11yProps(index)} />;
            })}
          </Tabs>
        </Box>
        <Box
          component="ul"
          sx={{
            width: '100%',
            minHeight: 'calc(100% - 48px)',
            overflow: 'auto',
            paddingLeft: 3,
            paddingTop: 3,
            marginTop: 0,
          }}
        >
          <SortableList
            updateCards={updatePromotionCollections}
            cards={promotionCollections}
            cardComponent={PromotionCollectionListItemComponent}
          ></SortableList>
        </Box>
      </Box>
      <ConfirmPromotionCollectionOrderChangeModal
        onConfirm={handleConfirmPromotionCollectionOrderChange}
        onCancel={handleCancelPromotionCollectionOrderChange}
        open={isConfirmOrderChangeModalOpened}
      />
    </>
  );
};
