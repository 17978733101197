import styled from 'styled-components';

export const ServiceIcon = styled.div<{ $imgUrl: string }>`
  display: flex;
  background-image: ${({ $imgUrl }) => `url(${$imgUrl})`};
  background-repeat: none;
  background-size: 100% 100%;
  background-position: center center;
  width: 50px;
  height: 50px;
`;
