import { ICreateServiceServiceData, ISaveServicePayload } from '../types';

export const transformServiceDataBeforeCreate = (
  service: ICreateServiceServiceData
): ISaveServicePayload => {
  return {
    data: {
      service,
    },
  };
};
