import React, { FC, useState } from 'react';
import { SxProps } from '@mui/system';
import {
  StandardTextFieldProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
} from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import {
  ImageTextField,
  StyledCircularProgress,
  UploadFileButton,
  UploadImageWrapper,
} from './elements';
import { Symbol } from './elements/Symbol';
import { uploadFile } from '@/features/stories/redux/stories.actions';
import { EUploadFileType } from '@/features/stories/types';
import { useAppDispatch } from '@/store';

type TTextFieldProps = StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps;

type TUploadFileWithTextFieldProps = {
  fileType?: EUploadFileType;
  handleFileUpload?: (url: string) => void;
  readOnly?: boolean;
  sx?: SxProps;
  error?: boolean;
  helperText?: string;
} & TTextFieldProps;

const fileTypeFilter: Record<EUploadFileType, string> = {
  [EUploadFileType.image]: 'image/*',
  [EUploadFileType.video]: 'video/*',
  [EUploadFileType.file]: '*',
};

export const UploadFileWithTextField: FC<TUploadFileWithTextFieldProps> = ({
  fileType = EUploadFileType.image,
  label,
  type,
  size,
  InputLabelProps,
  value,
  name,
  onChange,
  onBlur,
  onKeyUp,
  handleFileUpload,
  disabled,
  readOnly,
  sx,
  error,
  helperText,
}) => {
  const dispatch = useAppDispatch();
  const [isShowLoader, setIsShowLoader] = useState(false);

  const handleFileCapture = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    // Заметка: нужно добавить для АПИ ручки сохранения файла возможность сохранять
    // тип файла "любой файл" (для загрузки файла со списком уведомлений).
    // После добавления такой опции это "хак" можно будет удалить.
    let fileTypeForApi;
    if (fileType === EUploadFileType.file) {
      fileTypeForApi = EUploadFileType.image;
    } else {
      fileTypeForApi = fileType;
    }

    setIsShowLoader(true);
    dispatch(uploadFile({ fileType: fileTypeForApi, file: target.files[0] }))
      .unwrap()
      .then((url) => {
        setIsShowLoader(false);
        handleFileUpload(url);
      })
      .catch(() => {
        setIsShowLoader(false);
      });
  };

  return (
    <UploadImageWrapper sx={{ mb: '10px', ...sx }}>
      <ImageTextField
        label={label}
        type={type}
        size={size}
        InputLabelProps={InputLabelProps}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        inputProps={{ readOnly }}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
      <UploadFileButton variant="outlined" component="label" disabled={disabled || readOnly}>
        {isShowLoader ? (
          <StyledCircularProgress size="20px" />
        ) : (
          <Symbol $disabled={disabled || readOnly}>upload_file</Symbol>
        )}
        <input accept={fileTypeFilter[fileType]} type="file" hidden onChange={handleFileCapture} />
      </UploadFileButton>
    </UploadImageWrapper>
  );
};
