import { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Bounce, ToastContainer } from 'react-toastify';

import { AppContainer } from '@/app/elements';
import { router } from '@/app/Router';
import { muiLightTheme, muiDarkTheme } from '@/app/themes/mui-theme';
import { TRootState, useAppDispatch } from './store';
import { EMuiThemeMode } from './app/types';
import { getServices } from './features/sidebarMenus/redux/services.actions';
import { setDwpOnly, setMuiThemeMode } from './app/redux/appGeneral.slice';
import { RouterProvider } from 'react-router-dom';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { localStorageForFeatures } from './packages/localStorageForFeatures';

function App() {
  const dispatch = useAppDispatch();
  const muiThemeMode = useSelector((state: TRootState) => state.appGeneral.muiThemeMode);

  useLayoutEffect(() => {
    const themeModeLocalStorage = localStorageForFeatures.getItem({
      feature: 'appGeneral',
      key: 'themeMode',
    });

    if (themeModeLocalStorage !== null) {
      dispatch(setMuiThemeMode(themeModeLocalStorage));
    } else {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        dispatch(setMuiThemeMode(EMuiThemeMode.dark));
      } else {
        dispatch(setMuiThemeMode(EMuiThemeMode.light));
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getServices())
      .unwrap()
      .then((payload) => {
        const services = payload?.data?.services;
        const serviceWithDwpOnlyTruthyFound = services?.find((service) => {
          return service.dwp_only;
        });

        dispatch(setDwpOnly(!!serviceWithDwpOnlyTruthyFound));
      });
  }, []);

  return (
    <>
      <StyledThemeProvider
        theme={muiThemeMode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme}
      >
        <ThemeProvider theme={muiThemeMode === EMuiThemeMode.dark ? muiDarkTheme : muiLightTheme}>
          <CssBaseline />
          <AppContainer>
            <RouterProvider router={router} />
          </AppContainer>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={muiThemeMode}
            transition={Bounce}
          />
        </ThemeProvider>
      </StyledThemeProvider>
    </>
  );
}

export default App;
