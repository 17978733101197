import { Box } from '@mui/material';
import styled from 'styled-components';

export const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.popup.background};
  max-width: 600px;
  min-height: 180px;
  border-radius: 5px;
  padding: 24px;
`;
