export interface ILocalStorageForFeatureSetDataItem {
  feature: string;
  data: {
    key: string;
    value: any;
  };
}

export interface ILocalStorageForFeatureGetDataItem {
  feature: string;
  key: string;
}

export interface ILocalStorageForFeatures {
  backend: typeof localStorage | typeof sessionStorage;
  setBackend: (backend: typeof localStorage | typeof sessionStorage) => void;
  setItem: (data: ILocalStorageForFeatureSetDataItem) => any;
  getItem: (data: ILocalStorageForFeatureGetDataItem) => any;
}

const rootKey = 'smartPlatform';

const LocalStorageForFeatures = class implements ILocalStorageForFeatures {
  backend = localStorage;

  constructor() {
    const smartPlatform = this.backend.getItem(rootKey);
    if (!smartPlatform) {
      this.backend.setItem(rootKey, JSON.stringify({}));
    }
  }

  setBackend = (backend: typeof localStorage | typeof sessionStorage) => {
    this.backend = backend;
  };

  setItem = ({ feature, data }: ILocalStorageForFeatureSetDataItem) => {
    const smartPlatformStr = this.backend.getItem(rootKey);

    if (smartPlatformStr) {
      let smartPlatform: Record<string, any>;
      try {
        smartPlatform = JSON.parse(smartPlatformStr);
      } catch (e) {
        console.error(`Ошибка: возникла ошибка при парсинге данных для ключа ${rootKey}`);
      }

      if (!smartPlatform[feature]) {
        smartPlatform[feature] = {};
      }

      smartPlatform[feature][data.key] = data.value;

      this.backend.setItem(rootKey, JSON.stringify(smartPlatform));
    }
  };

  getItem = ({ feature, key }: ILocalStorageForFeatureGetDataItem) => {
    const smartPlatformStr = this.backend.getItem(rootKey);

    if (smartPlatformStr) {
      let smartPlatform: Record<string, any>;
      try {
        smartPlatform = JSON.parse(smartPlatformStr);
      } catch (e) {
        console.error(`Ошибка: возникла ошибка при парсинге данных для ключа ${rootKey}`);
      }

      if (smartPlatform[feature] && smartPlatform[feature][key] !== undefined) {
        return smartPlatform[feature][key];
      }

      return null;
    }

    return null;
  };
};

export const localStorageForFeatures = new LocalStorageForFeatures();
