import { TextField } from '@mui/material';
import { styled } from '@mui/system';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const CustomTextFieldWithStyles = styled(TextField)(({ theme }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return `
    label.Mui-disabled {
      color: ${styledTheme.colors.сustomTextField.disabled.label.text};
      background-color: ${styledTheme.colors.background.main};
      padding: 0px 5px;
      border-radius: 5px;
    }

    & div.Mui-disabled {
      background-color: ${styledTheme.colors.сustomTextField.disabled.background};
    }

    & .Mui-disabled {
      input {
        color: ${styledTheme.colors.input.disabled.inputText};
      }
      fieldset {
        border-color: transparent !important;
      }
    }
  `;
});
