import { styled } from '@mui/system';
import { css } from 'styled-components';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const Wrapper = styled('fieldset')<{
  $marginBottom?: string;
  $marginRight?: string;
  $paddingTop?: string;
  $width?: string;
}>(({ theme, $width = '100%', $marginRight = 0, $marginBottom }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return `
    position: relative;
    width: ${$width};
    padding: 10px;
    border: 1px solid ${styledTheme.colors.textFieldGroup.border};
    border-radius: 5px;
    margin-top: 0;
    margin-left: 0;
    margin-right: ${$marginRight};
    margin-bottom: ${$marginBottom};
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  `;
});
