import { ElementType } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const CopyTextButton = styled(Button)<ButtonProps & { component: ElementType }>(({
  theme,
}) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return `
    padding: 5px !important;
    border: 1px solid ${styledTheme.colors.сustomTextField.copyTextButton.border.main} !important;
    min-width: 37px !important;
    min-height: 37px;
    cursor: pointer;

    &:hover {
      border-color: ${styledTheme.colors.сustomTextField.copyTextButton.border.hover} !important;
    }
  `;
});
