import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { axiosInstance } from '@/shared/lib/axios';
import { MUTATION_ENDPOINTS, QUERY_ENDPOINTS } from '@/shared/lib/const';
import {
  EServicesScreenPlatform,
  IClientServicesData,
  ICreateServiceServiceData,
  IDeepLinkListData,
  IFetchServicesScreenClientServicesResponse,
  IFetchServicesScreenDeepLinkListResponse,
  IFetchServicesScreenServiceListResponse,
  IFetchServicesScreenServiceResponse,
  IFetchServicesScreenVersionListResponse,
  IFetchServicesScreenVisualizationDataResponse,
  IPublishServicesScreenServiceListResponse,
  ISaveServicesScreenServiceResponse,
  ISaveServicesScreenVersionResponse,
  IServiceListData,
  IServiceListItem,
  IServiceListVersionsData,
  IServicesScreenServiceData,
} from '../types';
import { TRootState } from '@/store';
import {
  transformServiceDataBeforeCreate,
  transformServicesDataBeforeVisualize,
  transformServiceDataBeforeUpdate,
} from '../utils';

export const fetchServicesScreenVersionList = createAsyncThunk<
  IServiceListVersionsData,
  void,
  unknown
>('servicesScreen/fetchVersionList', async () => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchServicesScreenVersionListResponse>(
    QUERY_ENDPOINTS.servicesScreen.servicesScreenVersionList.replace('[userId]', userId)
  );
  return response?.data?.data;
});

export const fetchServicesScreenServiceList = createAsyncThunk<IServiceListData, string, unknown>(
  'servicesScreen/fetchServiceList',
  async (version: string) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchServicesScreenServiceListResponse>(
      QUERY_ENDPOINTS.servicesScreen.serviceList
        .replace('[userId]', userId)
        .replace('[version]', version)
    );
    return response?.data?.data;
  }
);

export interface IPublishServicesScreenServiceListProps {
  version: string;
  platform: EServicesScreenPlatform;
}

export const publishServicesScreenServiceList = createAsyncThunk<
  IPublishServicesScreenServiceListResponse,
  IPublishServicesScreenServiceListProps,
  unknown
>('servicesScreen/publishServiceList', async ({ version, platform }) => {
  const userId = Cookies.get('userId') || '-1';
  const data = { data: { version, platform, publishing_process: { type: 'now' } } };

  const response = await axiosInstance.post<IPublishServicesScreenServiceListResponse>(
    MUTATION_ENDPOINTS.servicesScreen.publishServicesScreen.replace('[userId]', userId),
    data
  );
  return response?.data;
});

interface ISaveServicesScreenVersionProps {
  services: IServiceListItem[];
  name: string;
}

export const saveServicesScreenVersion = createAsyncThunk<
  ISaveServicesScreenVersionResponse['data'],
  ISaveServicesScreenVersionProps,
  unknown
>('servicesScreen/saveServicesScreenVersion', async ({ services, name }, { getState }) => {
  const userId = Cookies.get('userId') || '-1';
  const state = getState() as TRootState;
  const lastVersion = state.servicesScreen.serviceListData.last_version;
  const servicesIds = services.map((service) => {
    return service.id;
  });
  const data = { data: { version: lastVersion, name, service_ids: servicesIds } };

  const response = await axiosInstance.post<ISaveServicesScreenVersionResponse>(
    MUTATION_ENDPOINTS.servicesScreen.saveServicesScreenVersion.replace('[userId]', userId),
    data
  );
  return response?.data?.data;
});

export const fetchServicesScreenVisualizationData = createAsyncThunk<
  IFetchServicesScreenVisualizationDataResponse['data'],
  Array<IServiceListItem>,
  unknown
>('servicesScreen/fetchServicesScreenVisualizationData', async (services, { signal }) => {
  const userId = Cookies.get('userId') || '-1';
  const data = transformServicesDataBeforeVisualize(services);

  const response = await axiosInstance.post<IFetchServicesScreenVisualizationDataResponse>(
    QUERY_ENDPOINTS.servicesScreen.servicesScreenVisualization.replace('[userId]', userId),
    data,
    { signal }
  );
  return response?.data?.data;
});

export const fetchServicesScreenDeepLinkList = createAsyncThunk<IDeepLinkListData, void, unknown>(
  'servicesScreen/fetchDeepLinkList',
  async () => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchServicesScreenDeepLinkListResponse>(
      QUERY_ENDPOINTS.servicesScreen.deepLinkList.replace('[userId]', userId)
    );
    return response?.data?.data;
  }
);

export const fetchServicesScreenClientServices = createAsyncThunk<
  IClientServicesData,
  void,
  unknown
>('servicesScreen/fetchClientServices', async () => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchServicesScreenClientServicesResponse>(
    QUERY_ENDPOINTS.servicesScreen.clientServices.replace('[userId]', userId)
  );
  return response?.data?.data;
});

export const fetchServicesScreenService = createAsyncThunk<
  IServicesScreenServiceData,
  string,
  unknown
>('servicesScreen/fetchService', async (id) => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchServicesScreenServiceResponse>(
    QUERY_ENDPOINTS.servicesScreen.service.replace('[userId]', userId).replace('[item_id]', id)
  );
  return response?.data?.data;
});

export const fetchServicesScreenServiceBulkRequest = createAsyncThunk<
  IServicesScreenServiceData,
  string,
  unknown
>('servicesScreen/fetchServiceBulkRequest', async (id) => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchServicesScreenServiceResponse>(
    QUERY_ENDPOINTS.servicesScreen.service.replace('[userId]', userId).replace('[item_id]', id)
  );
  return response?.data?.data;
});

export const updateServicesScreenService = createAsyncThunk<
  ISaveServicesScreenServiceResponse['data'],
  IServicesScreenServiceData,
  unknown
>('servicesScreen/updateService', async (service) => {
  const userId = Cookies.get('userId') || '-1';
  const data = transformServiceDataBeforeUpdate(service);

  const response = await axiosInstance.post<ISaveServicesScreenServiceResponse>(
    MUTATION_ENDPOINTS.servicesScreen.service.replace('[userId]', userId),
    data
  );
  return response?.data?.data;
});

export const createServicesScreenService = createAsyncThunk<
  ISaveServicesScreenServiceResponse['data'],
  ICreateServiceServiceData,
  unknown
>('servicesScreen/createService', async (service) => {
  const userId = Cookies.get('userId') || '-1';
  const data = transformServiceDataBeforeCreate(service);

  const response = await axiosInstance.post<ISaveServicesScreenServiceResponse>(
    MUTATION_ENDPOINTS.servicesScreen.service.replace('[userId]', userId),
    data
  );
  return response?.data?.data;
});
