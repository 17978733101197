import { FC } from 'react';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

import { ICollapseMenuButtonProps } from './ICollapseMenuButtonProps';
import { MuiIconWrapper, CollapseMenuButtonWrapper, Text } from './elements';

export const CollapseMenuButton: FC<ICollapseMenuButtonProps> = ({
  isCollapsed,
  text,
  ...rest
}) => {
  return (
    <CollapseMenuButtonWrapper {...rest}>
      {isCollapsed && <MuiIconWrapper as={ChevronRightOutlinedIcon} />}
      {!isCollapsed && <MuiIconWrapper as={ChevronLeftOutlinedIcon} />}
      <Text>{text}</Text>
    </CollapseMenuButtonWrapper>
  );
};
