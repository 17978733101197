import styled, { css } from 'styled-components';

export const SlideWrapper = styled.div<{ $isShowError: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.main};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px 0px;
  border: 1px solid ${({ theme }) => theme.colors.background.module.border};
  background-color: ${({ theme }) => theme.colors.background.module.main};
  font-weight: 600;
  font-size: 14px;
  min-width: 217px;
  padding: 10px 20px;
  user-select: none;

  ${({ $isShowError }) =>
    $isShowError &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.authLayoutError};
    `};
`;
