import { Modal } from '@mui/material';
import { FC } from 'react';
import {
  CancelBtn,
  ConfirmBtn,
  Heading,
  ModalContainer,
  RemoveSlideModalMUI,
  Subheading,
} from './elements';

const modalContainerStyle = {
  boxShadow: 24,
};

export interface IRemoveSlideModalProps {
  open: boolean;
  onClose: () => void;
  onRemoveSlideBtnClick: () => void;
}

export const RemoveSlideModal: FC<IRemoveSlideModalProps> = ({
  open,
  onClose,
  onRemoveSlideBtnClick,
}) => {
  return (
    <RemoveSlideModalMUI
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer sx={modalContainerStyle}>
        <Heading id="modal-modal-title" variant="h6" component="h2">
          Удаление слайда
        </Heading>
        <Subheading id="modal-modal-description">Подтвердите действие</Subheading>
        <ConfirmBtn className="remove-slide-modal-btn" onClick={onRemoveSlideBtnClick}>
          Удалить
        </ConfirmBtn>
        <CancelBtn className="remove-slide-modal-btn" onClick={onClose}>
          Отменить
        </CancelBtn>
      </ModalContainer>
    </RemoveSlideModalMUI>
  );
};
