import * as React from 'react';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const StyledInputLabel = styled(InputLabel)<OutlinedInputProps>(({ theme }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return {
    '&': {
      color: styledTheme.colors.loginPage.input.label,
    },
    '&.Mui-error': {
      color: styledTheme.colors.loginPage.input.error,
    },
  };
});
