import styled from 'styled-components';

export const Text = styled.div`
  padding-top: 4px;
  font-family: Golos;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.logo};
  white-space: nowrap;
`;
