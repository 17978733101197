import cloneDeep from 'lodash.clonedeep';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { scenarios } from '../stub/scenariosData';
import {
  ESlideActionType,
  ESlideTextType,
  INewStory,
  IScenario,
  ISlideBackground,
  ISlideClose,
  ISlideDescription,
  ISlideFooter,
  ISlideProgress,
  ISlideTitle,
  IStory,
  IStoryPreview,
  IStorySequenceData,
  IStorySequenceItem,
  ITooltip,
  IViewDimensions,
} from '../types';
import { tooltip } from '../stub/tooltipData';
import {
  fetchScenarios,
  fetchStory,
  fetchStoryPreview,
  fetchStorySequence,
  saveStory,
  saveStorySequence,
} from './stories.actions';
import { ERequestStatus } from '@/shared/lib/types';
import { trimStoryColorFields } from '../utils';
import { getNewSlideInitial, newStoryInitial } from '../const ';
import orderBy from 'lodash.orderby';

interface IScenariosState {
  scenarios: IScenario[];
  scenariosStatus: ERequestStatus;
  activeScenarioName: string | null;
  storyPreviews: { [key: string]: IStoryPreview };
  storySequenceInitial: IStorySequenceItem[];
  storySequence: IStorySequenceItem[];
  storySequencesModified: { [key: string]: IStorySequenceItem[] };
  storySequenceVersion: number | null;
  fetchStorySequenceStatus: ERequestStatus;
  saveStorySequenceStatus: ERequestStatus;
  storySequenceItem: IStorySequenceItem;
  storyInitial: IStory;
  story: IStory;
  newStories: { [key: string]: INewStory };
  modifiedStories: { [key: string]: { [key: string]: IStory } };
  saveStoryStatus: ERequestStatus;
  fetchStoryStatus: ERequestStatus;
  tooltip: ITooltip;
  activeSlideIdInStoryPage: string;
  activeSlideIdInSlidePage: string;
  storyViewDimensions: IViewDimensions;
  isActiveSlideIdSetByUser: boolean;
}

const initialState: IScenariosState = {
  scenarios: [],
  scenariosStatus: ERequestStatus.idle,
  activeScenarioName: null,
  storyPreviews: {},
  storySequenceInitial: [],
  storySequence: [],
  storySequencesModified: {},
  storySequenceVersion: null,
  fetchStorySequenceStatus: ERequestStatus.idle,
  saveStorySequenceStatus: ERequestStatus.idle,
  storyInitial: {} as IStory,
  story: {} as IStory,
  newStories: {},
  modifiedStories: {},
  saveStoryStatus: ERequestStatus.idle,
  fetchStoryStatus: ERequestStatus.idle,
  storySequenceItem: {} as IStorySequenceItem,
  tooltip: tooltip,
  activeSlideIdInStoryPage: null,
  activeSlideIdInSlidePage: null,
  storyViewDimensions: {
    width: 320,
    height: 568,
  },
  isActiveSlideIdSetByUser: false,
};

const addNewStoryToStorySequenceIfExistsHelper = (
  state: IScenariosState,
  action: PayloadAction<string>
) => {
  const scenario = action.payload;
  const newStory = state.newStories[scenario];

  if (newStory) {
    const newStorySequenceItem = {
      isNewStory: true,
      isSavedStory: newStory.storyItem.isSavedStory,
      id: newStory.storyItem.id,
      name: newStory.storyItem.name,
      is_active: true,
      is_required: false,
      external_story_id: newStory.storyItem.external_story_id,
    };

    const isStorySequenceHasNewStory = state.storySequence.find((story) => {
      return story.isNewStory;
    });

    if (!isStorySequenceHasNewStory) {
      state.storySequence.push(newStorySequenceItem);
      state.storySequencesModified[scenario] = cloneDeep(state.storySequence);
    }
  }
};

const scenariosSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setScenarios: (state, action) => {
      state.scenarios = action.payload;
    },
    setActiveStorySequenceItemById: (state, action) => {
      const { storyId, isNewStory } = action.payload;
      if (isNewStory) {
        const currentStory = state.storySequence.find((story) => {
          return story.isNewStory;
        });
        if (currentStory) {
          state.storySequenceItem = currentStory;
        }
      } else {
        const currentStory = state.storySequence.find((story) => {
          return story.external_story_id === storyId;
        });
        if (currentStory) {
          state.storySequenceItem = currentStory || ({} as IStorySequenceItem);
        }
      }
    },
    setStorySequence: (state, action) => {
      const { storySequence, scenario } = action.payload;
      state.storySequence = storySequence;
      state.storySequencesModified[scenario] = cloneDeep(storySequence);
    },
    setStorySequenceFromModified: (state, action) => {
      const scenario = action.payload;
      state.storySequence = cloneDeep(state.storySequencesModified[scenario]);
    },
    setStoryIsRequired: (state, action) => {
      const { scenario, isNewStory, externalStoryId, checked } = action.payload;
      if (isNewStory) {
        const newStoryIndex = state.storySequence.findIndex((storySequenceItem) => {
          return storySequenceItem.isNewStory;
        });
        if (newStoryIndex !== -1) {
          state.storySequence.forEach((storySequenceItem) => {
            storySequenceItem.is_required = false;
          });
          state.storySequence[newStoryIndex].is_required = checked;
        }
      } else {
        const storyIndex = state.storySequence.findIndex((storySequenceItem) => {
          return storySequenceItem.external_story_id === externalStoryId;
        });
        if (storyIndex !== -1) {
          state.storySequence.forEach((storySequenceItem) => {
            storySequenceItem.is_required = false;
          });
          state.storySequence[storyIndex].is_required = checked;
        }
      }
      if (state.storySequencesModified[scenario]) {
        state.storySequencesModified[scenario] = cloneDeep(state.storySequence);
      }
    },
    setStoryIsActive: (state, action) => {
      const { scenario, isNewStory, externalStoryId, checked } = action.payload;
      if (isNewStory) {
        const newStoryIndex = state.storySequence.findIndex((storySequenceItem) => {
          return storySequenceItem.isNewStory;
        });
        if (newStoryIndex !== -1) {
          state.storySequence[newStoryIndex].is_active = checked;
        }
      } else {
        const storyIndex = state.storySequence.findIndex((storySequenceItem) => {
          return storySequenceItem.external_story_id === externalStoryId;
        });
        if (storyIndex !== -1) {
          state.storySequence[storyIndex].is_active = checked;
        }
      }

      if (state.storySequencesModified[scenario]) {
        state.storySequencesModified[scenario] = cloneDeep(state.storySequence);
      }
    },
    setStoryViewWidth: (state, action) => {
      state.storyViewDimensions.width = action.payload;
    },
    setStoryViewHeight: (state, action) => {
      state.storyViewDimensions.height = action.payload;
    },
    setActiveSlideIdInStoryPage: (state, action) => {
      state.activeSlideIdInStoryPage = action.payload;
    },
    setActiveSlideIdInSlidePage: (state, action) => {
      state.activeSlideIdInSlidePage = action.payload;
    },
    setSlides: (state, action) => {
      const { slides, scenario, externalStoryId } = action.payload;

      state.story.slides = slides;

      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      }
      if (!!state.modifiedStories[scenario] && !!state.modifiedStories[scenario][externalStoryId]) {
        state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
      }
    },
    setIsActiveSlideIdSetByUser: (state, action) => {
      state.isActiveSlideIdSetByUser = action.payload;
    },
    setActiveSlideExternalId: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (externalSlideId === 'new_slide' && slide.isNewSlide) {
          slide.external_slide_id = value;
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideName: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          slide.name = value;
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideTitleValue: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'title';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideTitle).value = value;
          } else {
            slide.slides.params.push({
              name: 'title',
              type: 'header' as ESlideTextType,
              value: value,
              text_color: '',
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideTitleTextColor: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'title';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideTitle).text_color = value;
          } else {
            slide.slides.params.push({
              name: 'title',
              type: 'header' as ESlideTextType,
              value: '',
              text_color: action.payload,
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideDescriptionValue: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'description';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideDescription).value = value;
          } else {
            slide.slides.params.push({
              name: 'description',
              type: 'header' as ESlideTextType,
              value: value,
              text_color: '',
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideDescriptionTextColor: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'description';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideDescription).text_color = value;
          } else {
            slide.slides.params.push({
              name: 'description',
              type: 'header' as ESlideTextType,
              value: '',
              text_color: value,
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideButtonValue: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'footer';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideFooter).value = value;
          } else {
            slide.slides.params.push({
              name: 'footer',
              type: 'button',
              value: value,
              on_click: {
                action_type: '' as ESlideActionType,
                action_value: '',
              },
              text_color: '',
              background_color: '',
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideButtonActionValue: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'footer';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideFooter).on_click.action_value = value;
          } else {
            slide.slides.params.push({
              name: 'footer',
              type: 'button',
              value: '',
              on_click: {
                action_type: '' as ESlideActionType,
                action_value: value,
              },
              text_color: '',
              background_color: '',
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideButtonActionType: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'footer';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideFooter).on_click.action_type = value;
          } else {
            slide.slides.params.push({
              name: 'footer',
              type: 'button',
              value: '',
              on_click: {
                action_type: value,
                action_value: '',
              },
              text_color: '',
              background_color: '',
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideButtonTextColor: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'footer';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideFooter).text_color = value;
          } else {
            slide.slides.params.push({
              name: 'footer',
              type: 'button',
              value: '',
              on_click: {
                action_type: '' as ESlideActionType,
                action_value: '',
              },
              text_color: value,
              background_color: '',
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideButtonBackgroundColor: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'footer';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideFooter).background_color = value;
          } else {
            slide.slides.params.push({
              name: 'footer',
              type: 'button',
              value: '',
              on_click: {
                action_type: '' as ESlideActionType,
                action_value: '',
              },
              text_color: '',
              background_color: value,
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideBackgroundImageValue: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'background';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideBackground).value = value;
          } else {
            slide.slides.params.push({
              name: 'background',
              type: 'image_url',
              value,
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideProgressColor: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'progress';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideProgress).progress_color = value;
          } else {
            slide.slides.params.push({
              name: 'progress',
              type: 'bar',
              progress_color: value,
              background_color: '',
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideProgressBackgroundColor: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'progress';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideProgress).background_color = value;
          } else {
            slide.slides.params.push({
              name: 'progress',
              type: 'bar',
              progress_color: '',
              background_color: value,
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveSlideCloseBackgroundColor: (state, action) => {
      const { value, scenario, externalStoryId, externalSlideId } = action.payload;
      state.story.slides = state.story?.slides?.map((slide) => {
        if (
          slide.external_slide_id === externalSlideId ||
          (externalSlideId === 'new_slide' && slide.isNewSlide)
        ) {
          const paramIndex = slide.slides.params.findIndex((param) => {
            return param.name === 'close';
          });
          if (paramIndex !== -1) {
            (slide.slides.params[paramIndex] as ISlideClose).background_color = value;
          } else {
            slide.slides.params.push({
              name: 'close',
              type: 'cross_button',
              on_clink: {
                action_type: 'close',
              },
              background_color: value,
            });
          }
        }
        return slide;
      });
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    addSlideToStory: (state, action) => {
      const { scenario, externalStoryId } = action.payload;
      const isNewSlideExists = state.story?.slides.find((slide) => {
        return slide.isNewSlide;
      });

      if (!isNewSlideExists) {
        state.story?.slides.push(
          getNewSlideInitial({ slideNumber: state.story?.slides?.length + 1 })
        );
      }

      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    removeSlideFromStory: (state, action) => {
      const { scenario, externalStoryId, externalSlideId, isNewSlide } = action.payload;

      let nextSlides;
      if (isNewSlide) {
        nextSlides = state.story?.slides.filter((slide) => {
          return !slide.isNewSlide;
        });
      } else {
        nextSlides = state.story?.slides.filter((slide) => {
          return slide.external_slide_id !== externalSlideId;
        });
      }

      state.story.slides = cloneDeep(nextSlides);

      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveStoryTooltipBackgroundImageValue: (state, action) => {
      const { value, scenario, externalStoryId } = action.payload;
      const params = state.story.preview.params;
      const paramIndex = params.findIndex((param) => {
        return param.name === 'background';
      });
      if (paramIndex !== -1) {
        (state.story.preview.params[paramIndex] as ISlideBackground).value = value;
      } else {
        state.story.preview.params.push({
          name: 'background',
          type: 'image_url',
          value,
        });
      }
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveStoryTooltipTextValue: (state, action) => {
      const { value, scenario, externalStoryId } = action.payload;
      const params = state.story.preview.params;
      const paramIndex = params.findIndex((param) => {
        return param.name === 'title';
      });
      if (paramIndex !== -1) {
        (state.story.preview.params[paramIndex] as ISlideTitle).value = value;
      } else {
        state.story.preview.params.push({
          name: 'title',
          type: 'text' as ESlideTextType,
          value,
          text_color: '',
        });
      }
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveStoryTooltipTextColor: (state, action) => {
      const { value, scenario, externalStoryId } = action.payload;
      const params = state.story.preview.params;
      const paramIndex = params.findIndex((param) => {
        return param.name === 'title';
      });
      if (paramIndex !== -1) {
        (state.story.preview.params[paramIndex] as ISlideTitle).text_color = value;
      } else {
        state.story.preview.params.push({
          name: 'title',
          type: 'text' as ESlideTextType,
          value: '',
          text_color: value,
        });
      }
      const existingNewStory = state.newStories[scenario];
      if (!!existingNewStory && state.story.isNewStory) {
        state.newStories[scenario].storyItem = cloneDeep(state.story);
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setActiveStoryName: (state, action) => {
      const { value, scenario, externalStoryId } = action.payload;
      state.story.name = value;
      const existingNewStory = state.newStories[scenario];
      if (existingNewStory && externalStoryId === 'new_story') {
        state.newStories[scenario].storyItem.name = value;
      } else {
        if (
          !!state.modifiedStories[scenario] &&
          !!state.modifiedStories[scenario][externalStoryId]
        ) {
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        } else {
          if (!state.modifiedStories[scenario]) {
            state.modifiedStories[scenario] = {};
          }
          state.modifiedStories[scenario][externalStoryId] = cloneDeep(state.story);
        }
      }
    },
    setNewStoryExternalId: (state, action) => {
      const { value, scenario } = action.payload;
      state.story.external_story_id = value;
      const existingNewStory = state.newStories[scenario];
      if (existingNewStory) {
        state.newStories[scenario].storyItem.external_story_id = value;
      }
    },
    createAndSetNewStory: (state, action) => {
      const scenario = action.payload;
      const existingNewStory = state.newStories[scenario];
      if (existingNewStory) {
        state.story = cloneDeep(existingNewStory.storyItem);
      } else {
        const newStory: IStory = cloneDeep(newStoryInitial);
        state.storyInitial = cloneDeep(newStory);
        state.story = cloneDeep(newStory);
        const newStoryBase: INewStory = {
          id: Date.now(),
          storyItem: cloneDeep(newStory),
        };
        state.newStories[scenario] = newStoryBase;
      }

      addNewStoryToStorySequenceIfExistsHelper(state, action);
    },
    addNewStoryToStorySequenceIfExists: (state, action) => {
      addNewStoryToStorySequenceIfExistsHelper(state, action);
    },
    setActiveStoryFromModified: (state, action) => {
      const { scenario, externalStoryId } = action.payload;
      if (!!state.modifiedStories[scenario] && !!state.modifiedStories[scenario][externalStoryId]) {
        state.story = state.modifiedStories[scenario][externalStoryId];
      }
    },
    setActiveStoryFromNew: (state, action) => {
      const scenario = action.payload;

      if (state.newStories[scenario]) {
        state.story = state.newStories[scenario].storyItem;
      }
    },
    clearStory: (state) => {
      state.storyInitial = {} as IStory;
      state.story = {} as IStory;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchScenarios.pending, (state, action) => {
        state.scenariosStatus = ERequestStatus.pending;
      })
      .addCase(fetchScenarios.fulfilled, (state, action) => {
        state.scenariosStatus = ERequestStatus.success;
        state.scenarios = action.payload;
      })
      .addCase(fetchScenarios.rejected, (state, action) => {
        state.scenariosStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchStorySequence.pending, (state, action) => {
        state.fetchStorySequenceStatus = ERequestStatus.pending;
        state.storySequence = [];
        state.storySequenceInitial = [];
        state.storySequenceVersion = null;
      })
      .addCase(fetchStorySequence.fulfilled, (state, action) => {
        state.fetchStorySequenceStatus = ERequestStatus.success;
        const storySequenceData = action.payload;

        state.storySequenceInitial = cloneDeep(storySequenceData.story_sequence);

        const storySequenceOrderedByIsActive = orderBy(
          storySequenceData.story_sequence,
          ['is_active'],
          ['desc']
        );
        state.storySequence = storySequenceOrderedByIsActive;
        state.storySequenceVersion = storySequenceData.version;
      })
      .addCase(fetchStorySequence.rejected, (state, action) => {
        state.fetchStorySequenceStatus = ERequestStatus.error;
        state.storySequence = [];
        state.storySequenceInitial = [];
        state.storySequenceVersion = null;
      });

    builder
      .addCase(saveStorySequence.pending, (state, action) => {
        state.saveStorySequenceStatus = ERequestStatus.pending;
      })
      .addCase(saveStorySequence.fulfilled, (state, action) => {
        const scenario = action.payload;
        if (state.storySequencesModified[scenario]) {
          delete state.storySequencesModified[scenario];
          delete state.newStories[scenario];
        }
        state.saveStorySequenceStatus = ERequestStatus.success;
      })
      .addCase(saveStorySequence.rejected, (state, action) => {
        state.saveStorySequenceStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchStory.pending, (state, action) => {
        state.fetchStoryStatus = ERequestStatus.pending;
      })
      .addCase(fetchStory.fulfilled, (state, action) => {
        state.fetchStoryStatus = ERequestStatus.success;
        state.storyInitial = action.payload;
        state.story = trimStoryColorFields(cloneDeep(action.payload));
      })
      .addCase(fetchStory.rejected, (state, action) => {
        state.fetchStoryStatus = ERequestStatus.error;
      });

    builder
      .addCase(saveStory.pending, (state, action) => {
        state.saveStoryStatus = ERequestStatus.pending;
      })
      .addCase(saveStory.fulfilled, (state, action) => {
        const { scenario, id } = action.payload;

        const storySequenceItemIndex = state.storySequence?.findIndex((storySequenceItem) => {
          return storySequenceItem.isNewStory;
        });
        if (storySequenceItemIndex !== -1) {
          state.storySequence[storySequenceItemIndex].external_story_id =
            state.story.external_story_id;
          state.storySequence[storySequenceItemIndex].name = state.story.name;
          state.storySequence[storySequenceItemIndex].isSavedStory = true;
          state.storySequence[storySequenceItemIndex].id = id;

          state.storySequencesModified[scenario] = cloneDeep(state.storySequence);
        }

        const existingNewStory = state.newStories[scenario];

        if (existingNewStory) {
          existingNewStory.storyItem.id = id;
          existingNewStory.storyItem.isSavedStory = true;

          if (!existingNewStory.storyItem.version) {
            existingNewStory.storyItem.version = 1;
          } else {
            existingNewStory.storyItem.version = existingNewStory.storyItem.version + 1;
          }
        }

        const existingModifiedStories =
          state.modifiedStories[scenario]?.[state.story.external_story_id];

        if (existingModifiedStories) {
          delete state.modifiedStories[scenario]?.[state.story.external_story_id];
        }

        state.saveStoryStatus = ERequestStatus.success;
      })
      .addCase(saveStory.rejected, (state, action) => {
        state.saveStoryStatus = ERequestStatus.error;
      });

    builder.addCase(fetchStoryPreview.fulfilled, (state, action) => {
      const story = action.payload;
      state.storyPreviews[story.external_story_id] = story.preview;
    });
  },
});

export const {
  setScenarios,
  setStoryIsRequired,
  setStoryIsActive,
  setActiveStorySequenceItemById,
  setStorySequence,
  setStorySequenceFromModified,
  setStoryViewWidth,
  setStoryViewHeight,
  setActiveSlideIdInStoryPage,
  setActiveSlideIdInSlidePage,
  setSlides,
  setIsActiveSlideIdSetByUser,
  setActiveSlideExternalId,
  setActiveSlideName,
  setActiveSlideTitleValue,
  setActiveSlideTitleTextColor,
  setActiveSlideDescriptionValue,
  setActiveSlideDescriptionTextColor,
  setActiveSlideButtonValue,
  setActiveSlideButtonActionValue,
  setActiveSlideButtonActionType,
  setActiveSlideButtonTextColor,
  setActiveSlideButtonBackgroundColor,
  setActiveSlideBackgroundImageValue,
  setActiveSlideProgressColor,
  setActiveSlideProgressBackgroundColor,
  setActiveSlideCloseBackgroundColor,
  setActiveStoryTooltipBackgroundImageValue,
  setActiveStoryTooltipTextValue,
  setActiveStoryTooltipTextColor,
  setActiveStoryName,
  addSlideToStory,
  removeSlideFromStory,
  createAndSetNewStory,
  addNewStoryToStorySequenceIfExists,
  setActiveStoryFromModified,
  setActiveStoryFromNew,
  clearStory,
  setNewStoryExternalId,
} = scenariosSlice.actions;

export default scenariosSlice.reducer;
