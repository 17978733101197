import { FC, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MainMenu } from '@/features/sidebarMenus/MainMenu';
import { MiddleMenu } from '@/features/sidebarMenus/MiddleMenu';
import { MainContentWrapper } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import { EMuiThemeMode } from '@/app/types';
import { setMuiThemeMode } from '@/app/redux/appGeneral.slice';
import { ROUTES } from '@/shared/lib/const';
import { AxiosRequestInterceptorProvider } from '@/shared/providers/AxiosRequestInterceptorProvider';
import { AxiosErrorInterceptorProvider } from '@/shared/providers/AxiosErrorInterceptorProvider';

interface IAuthorizedPagesLayoutProps {
  Content: FC;
  isShowMiddleMenu?: boolean;
}

export const AuthorizedPagesLayoutBasic: FC<IAuthorizedPagesLayoutProps> = ({
  Content,
  isShowMiddleMenu = true,
}) => {
  const navigate = useNavigate();
  const dwpOnly = useSelector((state: TRootState) => state.appGeneral.dwpOnly);

  useEffect(() => {
    if (dwpOnly && !window.location.pathname.includes('dispatchers-workplace')) {
      navigate(ROUTES.dispatchersWorkplace);
    }
  }, [navigate, dwpOnly]);

  return (
    <AxiosRequestInterceptorProvider>
      <AxiosErrorInterceptorProvider>
        <>
          <MainMenu></MainMenu>
          {isShowMiddleMenu && <MiddleMenu></MiddleMenu>}
          <MainContentWrapper>
            <Scrollbars>
              <Content />
            </Scrollbars>
          </MainContentWrapper>
        </>
      </AxiosErrorInterceptorProvider>
    </AxiosRequestInterceptorProvider>
  );
};
