import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { TRootState, useAppDispatch } from '@/store';
import { BannerContainer } from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import { BannerContent } from './components/BannerContent';
import { fetchBanner, fetchBannerList } from '../redux/scenarios.actions';
import { createNewBanner } from '../redux/scenarios.slice';

const Banner = () => {
  const { scenario: scenarioId, banner: bannerId } = useParams();
  const dispatch = useAppDispatch();
  const bannerList = useSelector((state: TRootState) => state.bannerScenarios.bannerList);
  const newBanner = useSelector((state: TRootState) => state.bannerScenarios.newBanner);

  const handleCreateNewBanner = useCallback(
    debounce((bannerId, newBanner) => {
      if (bannerId === 'new-banner' && !newBanner) {
        dispatch(createNewBanner());
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleCreateNewBanner(bannerId, newBanner);
  }, [handleCreateNewBanner, bannerId, newBanner]);

  useEffect(() => {
    if (bannerId) {
      dispatch(setMiddleMenuActiveBtn(bannerId));
    }
  }, [dispatch, bannerId]);

  useEffect(() => {
    if (bannerList) {
      let middleMenuOptions;
      if (newBanner) {
        middleMenuOptions = [...bannerList, { id: 'new-banner', key: 'new-banner' }];
      } else {
        middleMenuOptions = bannerList;
      }
      dispatch(setMiddleMenuOptions(middleMenuOptions));
    }
  }, [dispatch, bannerList, newBanner]);

  useEffect(() => {
    let fetchBannerPromise: any;

    if (scenarioId && bannerId) {
      dispatch(fetchBannerList(scenarioId));
    }
    if (bannerId !== 'new-banner') {
      fetchBannerPromise = dispatch(fetchBanner({ scenarioId, bannerId }));
    }

    return () => {
      fetchBannerPromise?.abort();
    };
  }, [dispatch, scenarioId, bannerId]);

  return (
    <BannerContainer>
      <BannerContent />
    </BannerContainer>
  );
};

export default Banner;
