import { useEffect, useLayoutEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, useFormik, FormikProvider } from 'formik';
import debounce from 'lodash.debounce';

import { TRootState, useAppDispatch } from '@/store';
import { Header } from './components/Header';
import Slides from './components/Slides';
import StoryView from './components/StoryView';
import {
  StoryViewContainer,
  StoryContainer,
  StoryContent,
  StoryViewWrapper,
  SaveBtnWrapper,
  StorySidebar,
} from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebarMenus/redux/services.slice';
import {
  addNewStoryToStorySequenceIfExists,
  createAndSetNewStory,
  removeSlideFromStory,
  setActiveStoryFromModified,
  setActiveStoryFromNew,
  setStorySequenceFromModified,
} from '../redux/stories.slice';
import { Tooltip } from './components/Tooltip';
import StoryViewDimensions from './components/StoryViewDimensions';
import { fetchStory, fetchStorySequence, saveStory } from '../redux/stories.actions';
import { StoryBasicInfo } from './components/StoryBasicInfo';
import { isValidStory } from '../utils';
import { ERequestStatus } from '@/shared/lib/types';
import { MainButton } from '@/shared/components/MainButton';
import { ROUTES } from '@/shared/lib/const';
import isEmpty from 'lodash.isempty';
import Yup from '@/shared/validations';
import { RemoveSlideModal } from './components/RemoveSlideModal';
import { showSuccessNotification } from '@/shared/utils';

const validationSchema = Yup.object().shape({
  storyExternalId: Yup.string().storyExternalIdValidation(),
  storyName: Yup.string().itemNameInputValidation(),
  tooltipImageUrl: Yup.string().externalLinkInputValidation(),
  tooltipTitleTextValue: Yup.string().storyTooltipTextInputValidation(),
  tooltipTitleTextColor: Yup.string().colorInputValidation(),
});

export interface IStoryFormikValues {
  storyExternalId: string;
  storyName: string;
  tooltipImageUrl: string;
  tooltipTitleTextValue: string;
  tooltipTitleTextColor: string;
}

export interface IRemoveSlideModalHandleOpenData {
  scenario: string;
  externalStoryId: string;
  externalSlideId: string;
  isNewSlide: boolean;
}

const Story = () => {
  const { story: externalStoryId, scenario } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevPathname = location.state?.from?.pathname;
  const dispatch = useAppDispatch();
  const story = useSelector((state: TRootState) => state.stories.story);
  const storyInitial = useSelector((state: TRootState) => state.stories.storyInitial);
  const saveStoryStatus = useSelector((state: TRootState) => state.stories.saveStoryStatus);
  const fetchStoryStatus = useSelector((state: TRootState) => state.stories.fetchStoryStatus);
  const newStories = useSelector((state: TRootState) => state.stories.newStories);
  const modifiedStories = useSelector((state: TRootState) => state.stories.modifiedStories);
  const storySequence = useSelector((state: TRootState) => state.stories.storySequence);
  const storySequencesModified = useSelector(
    (state: TRootState) => state.stories.storySequencesModified
  );
  const [isShowFormErrors, setIsShowFormErrors] = useState(false);
  const [isRemoveSlideModalOpened, setIsRemoveSlideModalOpened] = useState(false);
  const slideDataToRemove = useRef(null);

  const formik = useFormik({
    initialValues: {
      storyExternalId: '',
      storyName: '',
      tooltipImageUrl: '',
      tooltipTitleTextValue: '',
      tooltipTitleTextColor: '',
    },
    onSubmit: (values, actions) => {},
    validationSchema,
  });

  const isSaveBtnDisabled = useMemo(() => {
    return (
      saveStoryStatus === ERequestStatus.pending || fetchStoryStatus === ERequestStatus.pending
    );
  }, [saveStoryStatus, fetchStoryStatus]);

  useEffect(() => {
    let fetchStoryPromise: any;

    if (!!externalStoryId && !!scenario) {
      if (
        !!modifiedStories[scenario] &&
        !!modifiedStories[scenario][externalStoryId] &&
        externalStoryId !== 'new_story'
      ) {
        dispatch(setActiveStoryFromModified({ scenario, externalStoryId }));
      } else if (newStories[scenario] && externalStoryId === 'new_story') {
        dispatch(setActiveStoryFromNew(scenario));
      } else if (externalStoryId === 'new_story') {
        dispatch(createAndSetNewStory(scenario));
      } else {
        fetchStoryPromise = dispatch(fetchStory({ story: externalStoryId }));
      }
    }

    return () => {
      fetchStoryPromise?.abort();
    };
  }, [externalStoryId, scenario, modifiedStories, newStories, dispatch]);

  useEffect(() => {
    if (scenario) {
      if (storySequencesModified[scenario]) {
        dispatch(setStorySequenceFromModified(scenario));
      } else {
        dispatch(fetchStorySequence(scenario))
          .unwrap()
          .then(() => {
            dispatch(addNewStoryToStorySequenceIfExists(scenario));
          });
      }
    }
  }, [scenario, storySequencesModified, dispatch]);

  useEffect(() => {
    if (externalStoryId) {
      dispatch(setMiddleMenuActiveBtn(externalStoryId));
    }
  }, [externalStoryId]);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(storySequence));
  }, [storySequence, newStories, scenario, dispatch]);

  const handleSaveBtnClick = useCallback(() => {
    setIsShowFormErrors(true);
    formik.setTouched({
      storyExternalId: true,
      storyName: true,
      tooltipImageUrl: true,
      tooltipTitleTextValue: true,
      tooltipTitleTextColor: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors) && isValidStory(story)) {
        if (!isSaveBtnDisabled) {
          dispatch(saveStory(scenario))
            .unwrap()
            .then(() => {
              showSuccessNotification({
                message: 'Стори была успешно сохранена!',
              });
              if (externalStoryId !== 'new_story') {
                dispatch(fetchStory({ story: externalStoryId }));
              } else {
                dispatch(setActiveStoryFromNew(scenario));
              }
              formik.setTouched({
                storyExternalId: false,
                storyName: false,
                tooltipImageUrl: false,
                tooltipTitleTextValue: false,
                tooltipTitleTextColor: false,
              });
              setIsShowFormErrors(false);
            });
        }
      }
    });
  }, [isSaveBtnDisabled, externalStoryId, story, scenario]);

  useLayoutEffect(() => {
    setIsShowFormErrors(false);

    if (!matchPath(ROUTES.slide, decodeURIComponent(prevPathname))) {
      formik.resetForm();
    }
  }, [externalStoryId]);

  useEffect(() => {
    if (matchPath(ROUTES.slide, decodeURIComponent(prevPathname))) {
      const { isShowStoryFormErrors } = location.state;

      if (isShowStoryFormErrors) {
        setIsShowFormErrors(isShowStoryFormErrors);
      }
    }
  }, [prevPathname, location.state]);

  const handleValidateForm = useCallback(
    debounce((isShowFormErrors, story, formik) => {
      if (isShowFormErrors && story) {
        formik.setTouched({
          storyExternalId: true,
          storyName: true,
          tooltipImageUrl: true,
          tooltipTitleTextValue: true,
          tooltipTitleTextColor: true,
        });
        formik.validateForm();
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleValidateForm(isShowFormErrors, story, formik);
  }, [isShowFormErrors, story]);

  const handleRemoveSlideModalOpen = (slideData: IRemoveSlideModalHandleOpenData) => {
    setIsRemoveSlideModalOpened(true);
    slideDataToRemove.current = slideData;
  };
  const handleRemoveSlideModalClose = () => {
    setIsRemoveSlideModalOpened(false);
    slideDataToRemove.current = null;
  };
  const handleRemoveSlide = () => {
    if (slideDataToRemove.current) {
      setIsRemoveSlideModalOpened(false);
      dispatch(removeSlideFromStory(slideDataToRemove.current));
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <StoryContainer>
            <Header />
            <StoryContent>
              <StorySidebar>
                <StoryViewDimensions />
                <StoryBasicInfo formik={formik} />
                <Tooltip formik={formik} />
                <Slides
                  isShowFormErrors={isShowFormErrors}
                  handleRemoveSlideModalOpen={handleRemoveSlideModalOpen}
                />
              </StorySidebar>

              <StoryViewContainer>
                <StoryViewWrapper>
                  <StoryView />
                </StoryViewWrapper>
                <SaveBtnWrapper>
                  <MainButton
                    disabled={isSaveBtnDisabled}
                    onClick={handleSaveBtnClick}
                    sx={{
                      marginBottom: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    Сохранить
                  </MainButton>
                </SaveBtnWrapper>
              </StoryViewContainer>
            </StoryContent>
          </StoryContainer>
        </Form>
      </FormikProvider>
      <RemoveSlideModal
        open={isRemoveSlideModalOpened}
        onClose={handleRemoveSlideModalClose}
        onRemoveSlideBtnClick={handleRemoveSlide}
      />
    </>
  );
};

export default Story;
