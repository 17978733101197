import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { TRootState } from '@/store';
import { NavButtonWrapper, StyledLink, Symbol } from './elements';
import {
  ENotificationTaskStatus,
  INotificationTaskListItem,
} from '@/features/notificationTasks/types';
import { notificationTaskStatusNames } from '@/features/notificationTasks/const';
import { ROUTES } from '@/shared/lib/const';
import { StyledDataGrid } from '@/shared/components/StyledDataGrid';

interface INotificationsTableProps {
  notificationListItems: INotificationTaskListItem[];
}

export const NotificationsTable: FC<INotificationsTableProps> = (props) => {
  const { notificationListItems = [] } = props || {};
  const selectedPromotionIds = useSelector(
    (state: TRootState) => state.promotions.selectedPromotionIds
  );

  const rows = useMemo(() => {
    return notificationListItems.map((notificationListItem) => {
      return {
        id: notificationListItem.id,
        created_at: notificationListItem.created_at,
        sending_date: notificationListItem.sending_date,
        with_push: notificationListItem.with_push,
        status: notificationListItem.status,
      };
    });
  }, [notificationListItems]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'created_at',
      headerName: 'Дата создания',
      width: 170,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        dayjs(row.created_at).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      field: 'sending_date',
      headerName: 'Дата отправки',
      width: 170,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        dayjs(row.sending_date).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      field: 'with_push',
      headerName: 'С пушем',
      width: 100,
      renderCell: ({ row }: Partial<GridRowParams>) => (row.with_push ? 'Да' : 'Нет'),
    },
    {
      field: 'status',
      headerName: 'Статус',
      width: 170,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        notificationTaskStatusNames[row.status as ENotificationTaskStatus],
    },
    {
      field: '',
      headerName: 'Просмотр',
      sortable: false,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <NavButtonWrapper>
          <StyledLink to={ROUTES.notificationTask.replace(':notification', row.id)}>
            <Symbol>arrow_forward</Symbol>
          </StyledLink>
        </NavButtonWrapper>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableRowSelectionOnClick
        rowSelectionModel={selectedPromotionIds}
      />
    </div>
  );
};
