import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LogoLink = styled(Link)`
  display: block;
  width: 200px;
  padding: 10px 10px 10px 15px;
  text-decoration: none;
  user-select: none;
`;
