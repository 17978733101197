import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { axiosInstance } from '@/shared/lib/axios';
import { MUTATION_ENDPOINTS, QUERY_ENDPOINTS } from '@/shared/lib/const';
import { ILogin404Error, ILoginData, ILoginResponse } from '../types';
import { tranformToLoginPayload } from '../utils';
import { IServicesReponse } from '@/features/sidebarMenus/types';

export const login = createAsyncThunk<
  ILoginResponse | AxiosError<ILogin404Error>,
  ILoginData,
  unknown
>('authentication/login', async (payload, thunkAPI) => {
  try {
    const transfomedPayload = tranformToLoginPayload(payload);
    const response = await axiosInstance.post<ILoginResponse>(
      MUTATION_ENDPOINTS.login,
      transfomedPayload
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const checkIsAuth = createAsyncThunk<IServicesReponse, void, unknown>(
  'authentication/checkIsAuth',
  async (_, thunkAPI) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IServicesReponse>(
      QUERY_ENDPOINTS.services.replace('[userId]', userId)
    );
    return response.data;
  }
);
