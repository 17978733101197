import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { TRootState } from '@/store';
import { NavToServicesScreenVersionCellWrapper, StyledLink, Symbol } from './elements';
import { ROUTES } from '@/shared/lib/const';
import {
  EServicesScreenPlatform,
  EServiceListVersionStatus,
  IServiceListVersionsItem,
} from '@/features/servicesScreen/types';
import {
  serviceListVersionPlatformNames,
  serviceListVersionStatusNames,
} from '@/features/servicesScreen/const';
import { StyledDataGrid } from '@/shared/components/StyledDataGrid';

interface IVersionListTableProps {
  serviceListVersions: IServiceListVersionsItem[];
}

export const VersionListTable: FC<IVersionListTableProps> = (props) => {
  const { serviceListVersions = [] } = props || {};
  const selectedPromotionIds = useSelector(
    (state: TRootState) => state.promotions.selectedPromotionIds
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Название',
      width: 250,
    },
    {
      field: 'datetime',
      headerName: 'Дата',
      type: 'dateTime',
      width: 100,
      renderCell: ({ row }: Partial<GridRowParams>) => dayjs(row.datetime).format('DD/MM/YYYY'),
      valueGetter: (params) => new Date(params.value),
    },
    {
      field: 'author',
      headerName: 'Автор',
      width: 170,
    },
    {
      field: 'status',
      headerName: 'Статус',
      width: 140,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        serviceListVersionStatusNames[row.status as EServiceListVersionStatus],
    },
    {
      field: 'platform',
      headerName: 'Платформа',
      width: 140,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        serviceListVersionPlatformNames[row.platform as EServicesScreenPlatform] || 'Отсутствует',
    },
    {
      field: '',
      headerName: 'Просмотр',
      sortable: false,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <NavToServicesScreenVersionCellWrapper>
          <StyledLink to={ROUTES.servicesScreen.version.replace(':version', row.version)}>
            <Symbol>arrow_forward</Symbol>
          </StyledLink>
        </NavToServicesScreenVersionCellWrapper>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <StyledDataGrid
        rows={serviceListVersions}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          sorting: {
            sortModel: [{ field: 'datetime', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[5, 10]}
        disableRowSelectionOnClick
        rowSelectionModel={selectedPromotionIds}
        getRowId={(row) => row.index}
      />
    </div>
  );
};
