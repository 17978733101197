import { styled } from '@mui/system';
import Button from '@mui/material/Button';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';
import { SecondaryButton } from '@/shared/components/SecondaryButton';

export const LoginBtn = styled(SecondaryButton)(({ theme }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return `
    display: block !important;
    margin: 0 auto !important;
    color: ${styledTheme.colors.loginPage.button.text};
    borderColor: ${styledTheme.colors.loginPage.button.border};
    width: 100%;
    
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    };  
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  `;
});
