import { styled } from '@mui/system';

import { styledDarkTheme } from '@/app/themes/styled-dark-theme';
import { styledLightTheme } from '@/app/themes/styled-light-theme';
import { EMuiThemeMode } from '@/app/types';

export const MainWrapper = styled('div')(({ theme }) => {
  const styledTheme =
    theme.palette.mode === EMuiThemeMode.dark ? styledDarkTheme : styledLightTheme;

  return {
    'min-width': '100vw',
    'min-height': '100vh',
    'background-color': styledTheme.colors.loginPage.background,
    [theme.breakpoints.up('sm')]: {
      'padding-top': '100px',
      'padding-bottom': '100px',
    },
  };
});
