import { FC } from 'react';
import { IMuiMenuButtonProps } from './IMuiMenuButtonProps';
import { MuiMenuButtonWrapper, MuiIconWrapper, Text } from './elements';

export const MuiMenuButton: FC<IMuiMenuButtonProps> = ({ Icon, text, ...rest }) => {
  return (
    <MuiMenuButtonWrapper {...rest}>
      <MuiIconWrapper as={Icon} />
      <Text>{text}</Text>
    </MuiMenuButtonWrapper>
  );
};
